import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatErrorForAlert,
  sortTicketRanges,
  sortSubchannelsList,
  assembleBody,
  assembleBodyWithProductType,
  formatNumberRound,
  compareAndAddDummy,
  mergeData,
  padArray,
  filterVisibility,
  filterMetrics,
  getDistinctValues,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import KpiColumn from "../../components/Kpi/KpiColumn";
import KpiProgressChart from "../../components/Kpi/KpiProgressChart";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import SmallTitle from "../../components/Title/SmallTitle";
import Title from "../../components/Title/Title";
import Modal from "../../components/Modal/Modal";
import TableAG from "../../components/Table/TableAG";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import Button from "../../components/Button/Button";
import LayoutAcquirersFilter from "../../components/Layout/LayoutAcquirersFilter";
import {
  _variablesFour,
  _variablesSix,
  _variablesTwoOranges,
  _variablesFiveOranges,
  _variablesSixteen,
} from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Download from "../../components/Button/Download";

const colDefs = [
  { field: "exemption", flex: 1 },
  {
    field: "transaction_share",
    headerName: "Transaction Share",
    flex: 1,
  },
  { field: "approval_rate", headerName: "Approval Rate", flex: 1 },
];

const colsDefMerchantAdviseCode = [
  { field: "label", headerName: "MAC", flex: 1 },
  { field: "value", headerName: "Transaction Share", flex: 1 },
];

const AuthorizationAcquirers = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    userEmail,
    authoDates,
    settingsConfig,
    currency,
    distinctDict,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // acquirers
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirers, setSelectedAcquirers] = useState(null);
  const currencySymbol = currency === 826 ? "£" : "€";

  // charts
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [isLoadingBySubchannel, setIsLoadingBySubchannel] = useState(true);
  const [dataBySubchannel, setDataBySubchannel] = useState([]);
  const [rateBySubchannel, setRateBySubchannel] = useState([]);
  const [isLoadingByTicketRange, setIsLoadingByTicketRange] = useState(true);
  const [dataByTicketRange, setDataByTicketRange] = useState([]);
  const [rateByTicketRange, setRateByTicketRange] = useState([]);
  const [isLoadingByTokenPenetration, setIsLoadingByTokenPenetration] =
    useState(true);
  const [dataByTokenPenetration, setDataByTokenPenetration] = useState([]);
  const [rateByTokenPenetration, setRateByTokenPenetration] = useState([]);
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);
  const [rateByProgram, setRateByProgram] = useState([]);
  const [data3DSCompliance, setData3DSCompliance] = useState([]);
  const [rate3DSCompliance, setRate3DSCompliance] = useState([]);
  const [isLoading3DSCompliance, setIsLoading3DSCompliance] = useState(true);
  const [dataExemptionTypes, setDataExemptionTypes] = useState([]);
  const [isLoadingExemptionTypes, setIsLoadingExemptionTypes] = useState(true);
  const [isLoadingDeclineReasons, setIsLoadingDeclineReasons] = useState(true);
  const [dataDeclineReasons, setDataDeclineReasons] = useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const settings = settingsConfig ? settingsConfig : [];

  // charts countries
  const [dataKpisAcquirers, setDataKpisAcquirers] = useState([]);
  const [dataBySubchannelAcquirers, setDataBySubchannelAcquirers] = useState(
    []
  );
  const [rateBySubchannelAcquirers, setRateBySubchannelAcquirers] = useState(
    []
  );
  const [dataByTicketRangeAcquirers, setDataByTicketRangeAcquirers] = useState(
    []
  );
  const [rateByTicketRangeAcquirers, setRateByTicketRangeAcquirers] = useState(
    []
  );
  const [dataByTokenPenetrationAcquirers, setDataByTokenPenetrationAcquirers] =
    useState([]);
  const [rateByTokenPenetrationAcquirers, setRateByTokenPenetrationAcquirers] =
    useState([]);
  const [dataByProgramAcquirers, setDataByProgramAcquirers] = useState([]);
  const [rateByProgramAcquirers, setRateByProgramAcquirers] = useState([]);
  const [data3DSComplianceAcquirers, setData3DSComplianceAcquirers] = useState(
    []
  );
  const [rate3DSComplianceAcquirers, setRate3DSComplianceAcquirers] = useState(
    []
  );
  const [dataExemptionTypesAcquirers, setDataExemptionTypesAcquirers] =
    useState([]);
  const [dataDeclineReasonsAcquirers, setDataDeclineReasonsAcquirers] =
    useState([]);
  // product type
  const [productType, setProductType] = useState("");
  // merchant advise code
  const [dataMerchantAdviseCodeDonut, setDataMerchantAdviseCodeDonut] =
    useState([]);
  const [
    isLoadingMerchantAdviseCodeDonut,
    setIsLoadingMerchantAdviseCodeDonut,
  ] = useState(true);
  const [dataMerchantAdviseCode, setDataMerchantAdviseCode] = useState([]);
  const [isLoadingMerchantAdviseCode, setIsLoadingMerchantAdviseCode] =
    useState(true);
  const [dataMerchantAdviseCodeAcquirers, setDataMerchantAdviseCodeAcquirers] =
    useState([]);
  const [
    dataMerchantAdviseCodeDonutAcquirers,
    setDataMerchantAdviseCodeDonutAcquirers,
  ] = useState([]);
  //api response
  const [apiDataSubchannel, setApiDataSubchannel] = useState([]);
  const [apiDataSubchannelAcquirers, setApiDataSubchannelAcquirers] = useState(
    []
  );
  const [apiDataTicketRange, setApiDataTicketRange] = useState([]);
  const [apiDataTicketRangeAcquirers, setApiDataTicketRangeAcquirers] =
    useState([]);
  const [apiData3DSCompliance, setApiData3DSCompliance] = useState([]);
  const [apiData3DSComplianceAcquirers, setApiData3DSComplianceAcquirers] =
    useState([]);
  //merged data
  const [mergedDataSubchannel, setMergedDataSubchannel] = useState([]);
  const [mergedDataTicketRange, setMergedDataTicketRange] = useState([]);
  const [mergedData3DSCompliance, setMergedData3DSCompliance] = useState([]);
  const [mergedDataDeclineReasons, setMergedDataDeclineReasons] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    if (authoDates) {
      setDateLabels(authoDates);
    } else {
      setAlert({
        message: "Authorization dates not available",
        messageType: "error",
      });
    }
  }, [authoDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          "",
          productType
        );

        // get acquirers for select
        getAcquirers(body);
        getKpis(body);
        getBySubchannel(body);
        getByTicketRange(body);
        getByTokenPenetration(body);
        getByProgram(body);
        get3DSCompliance(body);
        getExemptionTypes(body);
        getDeclineReasons(body);
        getMerchantAdviseCode(body);
        getMerchantAdviseCodeDonut(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  // get charts data based on acquirer
  useEffect(() => {
    if (selectedAcquirers) {
      let body = assembleBodyAcquirer();

      getKpis(body, true);
      getBySubchannel(body, true);
      getByTicketRange(body, true);
      getByTokenPenetration(body, true);
      getByProgram(body, true);
      get3DSCompliance(body, true);
      getExemptionTypes(body, true);
      getDeclineReasons(body, true);
      getMerchantAdviseCode(body, true);
      getMerchantAdviseCodeDonut(body, true);
    }
  }, [timePeriod, dateLabels, selectedAcquirers, acquirers, productType]);

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingBySubchannel(true);
    setIsLoadingByTicketRange(true);
    setIsLoadingByTokenPenetration(true);
    setIsLoadingByProgram(true);
    setIsLoadingDeclineReasons(true);
    setIsLoading3DSCompliance(true);
    setIsLoadingExemptionTypes(true);
    setIsLoadingMerchantAdviseCode(true);
    setIsLoadingMerchantAdviseCodeDonut(true);
  };

  const getAcquirers = (body) => {
    FetchService.post("/api/dashboard/acquirers/autho", body)
      .then((res) => {
        let tempAcquirers = res.data.acquirers.sort((a, b) =>
          a.localeCompare(b)
        );
        tempAcquirers = tempAcquirers.map((el) => {
          return {
            value: el,
            text: el,
          };
        });

        setAcquirers(tempAcquirers);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const assembleBodyAcquirer = () => {
    let body = assembleBody(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      undefined,
      undefined,
      productType,
      undefined
    );

    if (selectedAcquirers !== 0 && selectedAcquirers !== "0") {
      body.acquirer_name = selectedAcquirers;
    }

    return body;
  };

  const handleSelectAcquirer = (e) => {
    setSelectedAcquirers(e);
  };

  const getKpis = (body, isAcquirers) => {
    setIsLoadingKpis(true);

    FetchService.post(`/api/dashboard/autho/acquirer/performance`, body)
      .then((res) => {
        isAcquirers ? setDataKpisAcquirers(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getBySubchannel = (body, isAcquirers) => {
    setIsLoadingBySubchannel(true);

    FetchService.post(
      `/api/dashboard/autho/acquirer/transactions-by-subchannel-and-approval-rate`,
      body
    )
      .then((res) => {
        const trx = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Transactions by subchannel",
          "label"
        );
        const approval = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Approval rate by subchannel",
          "label"
        );
        isAcquirers
          ? setApiDataSubchannelAcquirers(trx)
          : setApiDataSubchannel(trx);
        let cleanResTrx = trx.filter((el) => el.label !== "");
        cleanResTrx.sort((a, b) => a.label.localeCompare(b.label));
        let cleanResApproval = approval.filter((el) => el.label !== "");
        cleanResApproval.sort((a, b) => a.label.localeCompare(b.label));

        let tempData = cleanResTrx.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        sortSubchannelsList(tempData);
        isAcquirers
          ? setDataBySubchannelAcquirers(tempData)
          : setDataBySubchannel(tempData);

        let tempRate = cleanResApproval.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        sortSubchannelsList(tempRate);
        isAcquirers
          ? setRateBySubchannelAcquirers(tempRate)
          : setRateBySubchannel(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannel(false));
  };

  const getByTicketRange = (body, isAcquirers) => {
    setIsLoadingByTicketRange(true);

    FetchService.post(
      `/api/dashboard/autho/acquirer/transactions-by-ticket-range-and-approval-rate`,
      body
    )
      .then((res) => {
        const trx = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Transaction count by ticket range",
          "label"
        );
        const approval = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Approval rate by ticket range",
          "label"
        );
        isAcquirers
          ? setApiDataTicketRangeAcquirers(trx)
          : setApiDataTicketRange(trx);
        let tempData = trx.map((el) => {
          return {
            name: el.label ? el.label + ` ${currencySymbol}` : "",
            value: el.value ? el.value : 0,
          };
        });
        isAcquirers
          ? setDataByTicketRangeAcquirers(
              sortTicketRanges(tempData, `${currencySymbol}`)
            )
          : setDataByTicketRange(
              sortTicketRanges(tempData, `${currencySymbol}`)
            );

        let tempRate = approval.map((el) => {
          return {
            name: el.label ? el.label + ` ${currencySymbol}` : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isAcquirers
          ? setRateByTicketRangeAcquirers(
              sortTicketRanges(tempRate, `${currencySymbol}`)
            )
          : setRateByTicketRange(
              sortTicketRanges(tempRate, `${currencySymbol}`)
            );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicketRange(false));
  };

  const getByTokenPenetration = (body, isAcquirers) => {
    setIsLoadingByTokenPenetration(true);

    FetchService.post(
      `/api/dashboard/autho/acquirer/transactions-by-token-penetration-and-approval-rate`,
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label < b.label ? 1 : a.label > b.label ? -1 : 0
        );

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        isAcquirers
          ? setDataByTokenPenetrationAcquirers(tempData)
          : setDataByTokenPenetration(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isAcquirers
          ? setRateByTokenPenetrationAcquirers(tempRate)
          : setRateByTokenPenetration(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTokenPenetration(false));
  };

  const getByProgram = (body, isAcquirers) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      `/api/dashboard/autho/acquirer/transactions-by-program-and-approval-rate`,
      body
    )
      .then((res) => {
        const trx = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Transactions by program",
          "label"
        );
        const approval = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Approval rate by program",
          "label"
        );
        let cleanResTrx = trx.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );
        let cleanResApproval = approval.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let tempData = cleanResTrx.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        isAcquirers
          ? setDataByProgramAcquirers(tempData)
          : setDataByProgram(tempData);

        let tempRate = cleanResApproval.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isAcquirers
          ? setRateByProgramAcquirers(tempRate)
          : setRateByProgram(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const get3DSCompliance = (body, isAcquirers) => {
    setIsLoading3DSCompliance(true);
    FetchService.post("/api/dashboard/autho/acquirer/3DS-compliance", body)
      .then((res) => {
        const trx = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "3DS Exemptions Share",
          "label"
        );
        const approval = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Approval Rate by 3DS exemptions",
          "label"
        );
        isAcquirers
          ? setApiData3DSComplianceAcquirers(trx)
          : setApiData3DSCompliance(trx);
        let cleanResTrx = trx.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );
        let cleanResApproval = approval.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );
        let tempData = cleanResTrx.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempRate = cleanResApproval.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });

        isAcquirers
          ? setData3DSComplianceAcquirers(tempData)
          : setData3DSCompliance(tempData);

        isAcquirers
          ? setRate3DSComplianceAcquirers(tempRate)
          : setRate3DSCompliance(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3DSCompliance(false));
  };

  const getExemptionTypes = (body, isAcquirers) => {
    setIsLoadingExemptionTypes(true);
    FetchService.post("/api/dashboard/autho/acquirer/exemption-types", body)
      .then((res) => {
        let rowData = res.data.map((el) => {
          return {
            exemption: el.label ? el.label : "",
            transaction_share:
              formatNumberRound(Number(el.trx_share ? el.trx_share : 0)) + "%",
            approval_rate:
              formatNumberRound(
                Number(el.approval_rate ? el.approval_rate : 0)
              ) + "%",
          };
        });
        isAcquirers
          ? setDataExemptionTypesAcquirers(rowData)
          : setDataExemptionTypes(rowData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingExemptionTypes(false));
  };

  const getDeclineReasons = (body, isAcquirers) => {
    setIsLoadingDeclineReasons(true);

    FetchService.post(`/api/dashboard/autho/acquirer/decline-reasons`, body)
      .then((res) => {
        res.data = filterMetrics(
          res.data,
          settings,
          "Authorization",
          "Acquirers",
          "Decline reasons",
          "label"
        );
        let tempData = [];
        res.data.forEach((el) => {
          tempData.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        let tempReasons = listDeclineReasons;
        res.data.forEach((reason) => {
          if (!tempReasons.includes(reason.label)) {
            tempReasons.push(reason.label);
          }
        });
        setListDeclineReasons(tempReasons);

        isAcquirers
          ? setDataDeclineReasonsAcquirers(tempData)
          : setDataDeclineReasons(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDeclineReasons(false));
  };

  // donut data
  const getMerchantAdviseCodeDonut = (body, isAcquirers) => {
    setIsLoadingMerchantAdviseCodeDonut(true);
    FetchService.post(
      "/api/dashboard/autho/acquirer/merchant-advice-code-total-percentage",
      body
    )
      .then((res) => {
        let data = [];
        if (Object.keys(res.data).length !== 0) {
          data.push(
            {
              name: "Mac Present",
              value: res.data.mac_present ? res.data.mac_present : 0,
            },
            {
              name: "Mac Absent",
              value: res.data.mac_absent ? res.data.mac_absent : 0,
            }
          );
        }
        isAcquirers
          ? setDataMerchantAdviseCodeDonutAcquirers(data)
          : setDataMerchantAdviseCodeDonut(data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCodeDonut(false));
  };

  // table data
  const getMerchantAdviseCode = (body, isAcquirers) => {
    setIsLoadingMerchantAdviseCode(true);
    FetchService.post(
      "/api/dashboard/autho/acquirer/merchant-advice-code",
      body
    )
      .then((res) => {
        let tempData = [];
        res.data.map((el) => {
          tempData.push({
            label: el.label ? el.label : "",
            value: formatNumberRound(el.percent ? el.percent : 0) + "%",
          });
        });

        isAcquirers
          ? setDataMerchantAdviseCodeAcquirers(tempData)
          : setDataMerchantAdviseCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCode(false));
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  let pages = [
    {
      title: "Overview",
      link: "/authorization/home",
    },
    { title: "Geography", link: "/authorization/geography" },
    { title: "Benchmark", link: "/authorization/benchmark" },
    {
      title: "Acquirers",
      link: "/authorization/acquirers",
      active: true,
    },
    dashboardVersion2 && { title: "Issuers", link: "/authorization/issuers" },
  ];

  useEffect(() => {
    setMergedDataSubchannel(
      mergeData(
        apiDataSubchannel,
        compareAndAddDummy(
          apiDataSubchannelAcquirers,
          apiDataSubchannel,
          "label",
          "value",
          "approval_rate"
        ),
        [
          { source: "value", target: "trx_share_merchant" },
          { source: "approval_rate", target: "approval_rate_merchant" },
        ],
        [
          { source: "value", target: "trx_share_acquirer" },
          { source: "approval_rate", target: "approval_rate_acquirer" },
        ],
        "label"
      )
    );
  }, [apiDataSubchannel, apiDataSubchannelAcquirers]);

  useEffect(() => {
    setMergedDataTicketRange(
      mergeData(
        apiDataTicketRange,
        compareAndAddDummy(
          apiDataTicketRangeAcquirers,
          apiDataTicketRange,
          "label"
        ),
        [
          { source: "value", target: "value_merchant" },
          { source: "approval_rate", target: "approval_rate_merchant" },
        ],
        [
          { source: "value", target: "value_acquirer" },
          { source: "approval_rate", target: "approval_rate_acquirer" },
        ],
        "label"
      )
    );
  }, [apiDataTicketRange, apiDataTicketRangeAcquirers]);

  useEffect(() => {
    setMergedData3DSCompliance(
      mergeData(
        apiData3DSCompliance,
        compareAndAddDummy(
          apiData3DSComplianceAcquirers,
          apiData3DSCompliance,
          "label",
          "trx_share",
          "approval_rate"
        ),
        [
          { source: "trx_share", target: "trx_share_merchant" },
          { source: "approval_rate", target: "approval_rate_merchant" },
        ],
        [
          { source: "trx_share", target: "trx_share_acquirer" },
          { source: "approval_rate", target: "approval_rate_acquirer" },
        ],
        "label"
      )
    );
  }, [apiData3DSCompliance, apiData3DSComplianceAcquirers]);

  useEffect(() => {
    setMergedDataDeclineReasons(
      mergeData(
        dataDeclineReasons,
        compareAndAddDummy(
          dataDeclineReasonsAcquirers,
          dataDeclineReasons,
          "name",
          "percent"
        ),
        [{ source: "percent", target: "percent_merchant" }],
        [{ source: "percent", target: "percent_acquirer" }],
        "name"
      )
    );
  }, [dataDeclineReasons, dataDeclineReasonsAcquirers]);

  // Visibility settings
  const getVisibility = (graphName) => {
    return filterVisibility(
      "chart",
      settings,
      "Authorization",
      "Acquirers",
      graphName
    );
  };

  const approvedViz = getVisibility("Transactions approved");
  const declinedViz = getVisibility("Transactions declined");
  const approvalRateViz = getVisibility("Approval rate");
  const atvApprovedViz = getVisibility("Average ticket Approved transaction");
  const atvDeclinedViz = getVisibility("Average ticket Declined transaction");
  const trxBySubchannelViz = getVisibility("Transactions by subchannel");
  const approvalBySubchannelViz = getVisibility("Approval rate by subchannel");
  const trxByTicketRangeViz = getVisibility(
    "Transaction count by ticket range"
  );
  const approvalByTicketRangeViz = getVisibility(
    "Approval rate by ticket range"
  );
  const trxByTokenPenetrationViz = getVisibility(
    "Transactions by token penetration"
  );
  const approvalByTokenPenetrationViz = getVisibility(
    "Approval rate by token penetration"
  );
  const trxByProgramViz = getVisibility("Transactions by program");
  const approvalByProgramViz = getVisibility("Approval rate by program");
  const versionsShareViz = getVisibility("3DS Exemptions Share");
  const approval3DSViz = getVisibility("Approval Rate by 3DS exemptions");
  const macViz = getVisibility("Transaction share by merchant advice code");
  const declineReasonsViz = getVisibility("Decline reasons");

  const trxApprovedContent = (data) =>
    approvedViz
      ? {
          leftContent:
            (data
              ? data >= 1000
                ? formatNumberFull(data / 1000)
                : data / 1000
              : "0") + " K",
          righContent: "Transactions approved",
          color: _variablesTwoOranges.primaryOrange,
        }
      : {};

  const trxDeclinedContent = (data) =>
    declinedViz
      ? {
          leftContent:
            (data
              ? data >= 1000
                ? formatNumberFull(data / 1000)
                : data / 1000
              : "0") + " K",
          righContent: "Transactions declined",
          color: _variablesTwoOranges.primaryOrangeLight,
        }
      : {};

  const atvApprovedContent = (data) =>
    atvApprovedViz
      ? {
          leftContent:
            (data ? formatNumberFull(data) : "0") + ` ${currencySymbol}`,
          righContent: "Average ticket approved",
          color: _variablesTwoOranges.primaryOrange,
        }
      : {};

  const atvDeclinedContent = (data) =>
    atvDeclinedViz
      ? {
          leftContent:
            (data ? formatNumberFull(data) : "0") + ` ${currencySymbol}`,
          righContent: "Average ticket declined",
          color: _variablesTwoOranges.primaryOrangeLight,
        }
      : {};

  const approvalRateContent = (data) =>
    approvalRateViz
      ? {
          leftContent: (
            <KpiProgressChart
              bar={{
                value: data,
                color: _variablesTwoOranges.primaryOrange,
              }}
            />
          ),
          righContent: "Approval rate",
          autofit: false,
        }
      : {};

  return (
    <LayoutPageContainer
      title={"Authorization"}
      pages={pages}
      alert={alert}
      setAlert={setAlert}
      settings={settings}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingBySubchannel,
          isLoadingByTicketRange,
          isLoadingByTokenPenetration,
          isLoadingByProgram,
          isLoadingDeclineReasons,
          isLoading3DSCompliance,
          isLoadingExemptionTypes,
          isLoadingMerchantAdviseCode,
          isLoadingMerchantAdviseCodeDonut,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}

      <hr />
      <FilterBoxSticky>
        <LayoutAcquirersFilter
          tooltipMerchant={
            "Merchant performance includes the overall performances.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipAcquirer={
            "Acquirer performance indicates the performances of selected Acquirer.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          options={acquirers}
          onChange={handleSelectAcquirer}
          hideNames={userEmail === "demo@mastercard.it"}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Merchant</Title>
            <KpiColumn
              kpis={[
                trxApprovedContent(dataKpis.transaction_approved),
                trxDeclinedContent(dataKpis.transaction_denied),
                atvApprovedContent(dataKpis.ticket_approved),
                atvDeclinedContent(dataKpis.ticket_denied),
                approvalRateContent(dataKpis.approval_rate),
              ]}
            />

            <hr />

            {trxBySubchannelViz && (
              <div className="mb-4">
                <SmallTitleFlex
                  title={"Transactions by subchannel"}
                  tooltip={
                    "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <McDonutChartSingle
                  data={dataBySubchannel}
                  side={"legend"}
                  palette={_variablesFour}
                  tooltipMeasureUnit="%"
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-subchannel-and-approval-rate"
                  )}
                />
              </div>
            )}

            {approvalBySubchannelViz && (
              <>
                <SmallTitleFlex
                  title="Approval rate by subchannel"
                  tooltip={
                    "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
                  }
                ></SmallTitleFlex>
                <McProgressChartBlock
                  data={padArray(rateBySubchannel, rateBySubchannelAcquirers)}
                  palette={_variablesFour}
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-subchannel-and-approval-rate"
                  )}
                />
              </>
            )}

            <hr style={{ marginBottom: "5.6rem" }} />

            {trxByTicketRangeViz && (
              <div className="mb-4">
                <SmallTitleFlex
                  title={"Transaction count by ticket range"}
                  tooltip={
                    `Card not present transactions split by ticket range incidence: 0${currencySymbol}, 0.01-30${currencySymbol}, 30.01-100${currencySymbol}, 100.01-250${currencySymbol}, 250.01-500${currencySymbol}, or +500${currencySymbol}.<br /><br />` +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <McDonutChartSingle
                  data={dataByTicketRange}
                  side={"legend"}
                  palette={_variablesSix}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {approvalByTicketRangeViz && (
              <>
                <SmallTitle>Approval rate by ticket range</SmallTitle>
                <McProgressChartBlock
                  data={padArray(rateByTicketRange, rateByTicketRangeAcquirers)}
                  palette={_variablesSix}
                />
              </>
            )}

            <hr />

            {trxByTokenPenetrationViz && (
              <div className="mb-4">
                <SmallTitleFlex
                  title={"Transactions by token penetration"}
                  tooltip={
                    "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <McDonutChartSingle
                  data={dataByTokenPenetration}
                  side={"legend"}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {approvalByTokenPenetrationViz && (
              <>
                <SmallTitle>Approval rate by token penetration</SmallTitle>
                <McProgressChartBlock
                  data={padArray(
                    rateByTokenPenetration,
                    rateByTokenPenetrationAcquirers
                  )}
                  palette={_variablesTwoOranges}
                />
              </>
            )}

            <hr />
            {trxByProgramViz && (
              <div className="mb-4">
                <SmallTitleFlex
                  title={"Transactions by program"}
                  tooltip={
                    "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <McDonutChartSingle
                  data={dataByProgram}
                  side={"legend"}
                  palette={_variablesFiveOranges}
                  tooltipMeasureUnit="%"
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-program-and-approval-rate"
                  )}
                />
              </div>
            )}
            {approvalByProgramViz && (
              <>
                <SmallTitle>Approval rate by program</SmallTitle>
                <McProgressChartBlock
                  data={padArray(rateByProgram, rateByProgramAcquirers)}
                  palette={_variablesFiveOranges}
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-program-and-approval-rate"
                  )}
                />
              </>
            )}

            {dashboardVersion2 && (
              <>
                <hr />
                {versionsShareViz && (
                  <div className="mb-4">
                    <>
                      <SmallTitleFlex
                        title={"3DS Exemptions Share"}
                        tooltip={
                          "Transaction share of various CNP compositions along with exemptions."
                        }
                      />
                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={data3DSCompliance}
                          palette={_variablesSixteen}
                          tooltipMeasureUnit="%"
                          distinctValues={getDistinctValues(
                            distinctDict,
                            "3DS-compliance"
                          )}
                        />
                        <div
                          style={{
                            marginTop: "",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <b>Transaction Share</b>
                          <McDonutSideLegend
                            data={data3DSCompliance}
                            palette={_variablesSixteen}
                            distinctValues={getDistinctValues(
                              distinctDict,
                              "3DS-compliance"
                            )}
                          />
                        </div>
                      </div>
                    </>
                  </div>
                )}
                {approval3DSViz && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SmallTitleFlex
                      title="Approval Rate by 3DS exemptions"
                      tooltip={
                        "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                        "Approval rate is defined as number of successfully approved transactions over total transactions"
                      }
                    />
                    <McProgressChartBlock
                      data={rate3DSCompliance}
                      palette={_variablesSixteen}
                      distinctValues={getDistinctValues(
                        distinctDict,
                        "3DS-compliance"
                      )}
                    />
                    <Button
                      text="Exemptions Details"
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        //marginLeft:"6rem",
                      }}
                      dataBsToggle="modal"
                      dataBsTarget={"exemptionsModal"}
                    />
                    <Modal
                      dataBsTarget="exemptionsModal"
                      title={<b>Non-3DS Exemption Types</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colDefs}
                            rowData={dataExemptionTypes}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AuthoAcquirers"
                        chartName="Non-3DS Exemption Types"
                        data={dataExemptionTypes}
                        graphType="pie"
                        label={"Exemption Type"}
                        productType={productType}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Transaction Share",
                            value: "transaction_share",
                          },
                          { field: "Approval Rate", value: "approval_rate" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                  </div>
                )}
                <hr />
                {macViz && (
                  <div className="mb-4">
                    <>
                      <SmallTitleFlex
                        title={
                          "Transaction share by merchant advice code (MAC)"
                        }
                        tooltip={
                          "Share of declined transactions split by MAC Present or Absent status"
                        }
                      />
                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={dataMerchantAdviseCodeDonut}
                          palette={_variablesTwoOranges}
                          tooltipMeasureUnit="%"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <span>
                            <b>Transaction Share </b>
                            (within declined transactions)
                          </span>
                          <McDonutSideLegend
                            data={dataMerchantAdviseCodeDonut.map((el) => {
                              return { name: el.name, value: el.value };
                            })}
                            palette={_variablesTwoOranges}
                          />
                        </div>
                      </div>
                      <Modal
                        dataBsTarget="authMethodModal"
                        title={<b>Deepdive of MAC transaction share</b>}
                        modalBody={
                          <div
                            style={{
                              width: "100%",
                              height: "30rem",
                              display: "flex",
                            }}
                          >
                            <TableAG
                              colDefs={colsDefMerchantAdviseCode}
                              rowData={dataMerchantAdviseCode}
                            />
                          </div>
                        }
                      >
                        <Download
                          section="AuthoAcquirers"
                          chartName="MAC"
                          data={dataMerchantAdviseCode}
                          graphType="pie"
                          productType={productType}
                          acquirer={selectedAcquirers}
                          label={"MAC"}
                          timeRange={
                            dateLabels[timePeriod[0]] +
                            " - " +
                            dateLabels[timePeriod[1]]
                          }
                          fields={[
                            { field: "Transaction Share", value: "value" },
                          ]}
                          iconSize="sm"
                          className="h-8 w-8"
                        />
                      </Modal>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          text="Details"
                          style={{
                            fontSize: "12pt",
                            padding: "0.5rem",
                            marginTop: "3rem",
                            width: "15rem",
                            // marginLeft: "auto",
                          }}
                          dataBsToggle="modal"
                          dataBsTarget={"authMethodModal"}
                        />
                      </div>
                    </>
                  </div>
                )}
              </>
            )}
            <hr />
            {declineReasonsViz && (
              <>
                <SmallTitleFlex
                  title={"Decline reasons"}
                  tooltip={
                    "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                    "Area of each rectangle indicates the magnitude of each decline reason."
                  }
                />
                <McTreemapChart
                  data={dataDeclineReasons}
                  listDeclineReasons={listDeclineReasons}
                />
              </>
            )}
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Peer</Title>
            <KpiColumn
              kpis={[
                trxApprovedContent(dataKpisAcquirers.transaction_approved),
                trxDeclinedContent(dataKpisAcquirers.transaction_denied),
                atvApprovedContent(dataKpisAcquirers.ticket_approved),
                atvDeclinedContent(dataKpisAcquirers.ticket_denied),
                approvalRateContent(dataKpisAcquirers.approval_rate),
              ]}
            />

            <hr />

            {trxBySubchannelViz && (
              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"Transactions by subchannel"}
                    tooltip={
                      "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                      "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                    }
                  />
                  <Download
                    disabled={selectedAcquirers === null}
                    section="AuthoAcquirers"
                    chartName="Subchannel"
                    data={mergedDataSubchannel}
                    graphType="pie"
                    productType={productType}
                    acquirer={selectedAcquirers}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    label={"Subchannel"}
                    fields={[
                      {
                        field: "Merchant Share (%)",
                        value: "trx_share_merchant",
                      },
                      {
                        field: "Acquirer Share (%)",
                        value: "trx_share_acquirer",
                      },
                      {
                        field: "Merchant Approval Rate (%)",
                        value: "approval_rate_merchant",
                      },
                      {
                        field: "Acquirer Approval Rate (%)",
                        value: "approval_rate_acquirer",
                      },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <McDonutChartSingle
                  data={dataBySubchannelAcquirers}
                  side={"legend"}
                  palette={_variablesFour}
                  tooltipMeasureUnit="%"
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-subchannel-and-approval-rate"
                  )}
                />
              </div>
            )}

            {approvalBySubchannelViz && (
              <>
                <SmallTitleFlex
                  title="Approval rate by subchannel"
                  tooltip={
                    "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
                  }
                ></SmallTitleFlex>
                <McProgressChartBlock
                  data={padArray(rateBySubchannelAcquirers, rateBySubchannel)}
                  palette={_variablesFour}
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-subchannel-and-approval-rate"
                  )}
                />
              </>
            )}

            <hr style={{ marginBottom: "2rem" }} />

            {trxByTicketRangeViz && (
              <div
                className="d-flex justify-content-end"
                style={{ marginBottom: "1rem" }}
              >
                <Download
                  disabled={selectedAcquirers === null}
                  section="AuthoAcquirers"
                  chartName="TicketRange"
                  productType={productType}
                  acquirer={selectedAcquirers}
                  data={mergedDataTicketRange}
                  graphType="pie"
                  label={"Ticket Range"}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "Merchant Share (%)", value: "value_merchant" },
                    { field: "Acquirer Share (%)", value: "value_acquirer" },
                    {
                      field: "Merchant Approval Rate (%)",
                      value: "approval_rate_merchant",
                    },
                    {
                      field: "Acquirer Approval Rate (%)",
                      value: "approval_rate_acquirer",
                    },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </div>
            )}

            {trxByTicketRangeViz && (
              <div className="mb-4">
                <SmallTitleFlex
                  title={"Transaction count by ticket range"}
                  tooltip={
                    `Card not present transactions split by ticket range incidence: 0${currencySymbol}, 0.01-30${currencySymbol}, 30.01-100${currencySymbol}, 100.01-250${currencySymbol}, 250.01-500${currencySymbol}, or +500${currencySymbol}.<br /><br />` +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <McDonutChartSingle
                  data={dataByTicketRangeAcquirers}
                  side={"legend"}
                  palette={_variablesSix}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {approvalByTicketRangeViz && (
              <>
                <SmallTitle>Approval rate by ticket range</SmallTitle>
                <McProgressChartBlock
                  data={padArray(rateByTicketRangeAcquirers, rateByTicketRange)}
                  palette={_variablesSix}
                />
              </>
            )}
            <hr />

            {trxByTokenPenetrationViz && (
              <div className="mb-4">
                <SmallTitleFlex
                  title={"Transactions by token penetration"}
                  tooltip={
                    "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <McDonutChartSingle
                  data={dataByTokenPenetrationAcquirers}
                  side={"legend"}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {approvalByTokenPenetrationViz && (
              <>
                <SmallTitle>Approval rate by token penetration</SmallTitle>
                <McProgressChartBlock
                  data={padArray(
                    rateByTokenPenetrationAcquirers,
                    rateByTokenPenetration
                  )}
                  palette={_variablesTwoOranges}
                />
              </>
            )}

            <hr />
            {trxByProgramViz && (
              <div className="mb-4">
                <SmallTitleFlex
                  title={"Transactions by program"}
                  tooltip={
                    "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <McDonutChartSingle
                  data={dataByProgramAcquirers}
                  side={"legend"}
                  palette={_variablesFiveOranges}
                  tooltipMeasureUnit="%"
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-program-and-approval-rate"
                  )}
                />
              </div>
            )}

            {approvalByProgramViz && (
              <>
                <SmallTitle>Approval rate by program</SmallTitle>
                <McProgressChartBlock
                  data={padArray(rateByProgramAcquirers, rateByProgram)}
                  palette={_variablesFiveOranges}
                  distinctValues={getDistinctValues(
                    distinctDict,
                    "transactions-by-program-and-approval-rate"
                  )}
                />
              </>
            )}

            {dashboardVersion2 && (
              <>
                <hr />
                {versionsShareViz && (
                  <div className="mb-4">
                    <>
                      <div className="d-flex justify-content-between">
                        <SmallTitleFlex
                          title={"3DS Exemptions Share"}
                          tooltip={
                            "Transaction share of various CNP compositions along with exemptions."
                          }
                        />
                        <Download
                          disabled={selectedAcquirers === null}
                          section="AuthoAcquirers"
                          chartName="3DS Exemptions"
                          data={mergedData3DSCompliance}
                          graphType="pie"
                          label={"3DS Exemptions"}
                          productType={productType}
                          acquirer={selectedAcquirers}
                          timeRange={
                            dateLabels[timePeriod[0]] +
                            " - " +
                            dateLabels[timePeriod[1]]
                          }
                          fields={[
                            {
                              field: "Merchant Share (%)",
                              value: "trx_share_merchant",
                            },
                            {
                              field: "Acquirer Share (%)",
                              value: "trx_share_acquirer",
                            },
                            {
                              field: "Merchant Approval Rate (%)",
                              value: "approval_rate_merchant",
                            },
                            {
                              field: "Acquirer Approval Rate (%)",
                              value: "approval_rate_acquirer",
                            },
                          ]}
                          iconSize="sm"
                          className="h-8 w-8"
                        />
                      </div>
                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={data3DSComplianceAcquirers}
                          palette={_variablesSixteen}
                          tooltipMeasureUnit="%"
                        />
                        <div
                          style={{
                            marginTop: "",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          {data3DSComplianceAcquirers.length > 0 && (
                            <>
                              <b>Transaction Share</b>
                              <McDonutSideLegend
                                data={compareAndAddDummy(
                                  data3DSComplianceAcquirers,
                                  data3DSCompliance,
                                  "name",
                                  "value"
                                )}
                                palette={_variablesSixteen}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                )}
                {approval3DSViz && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SmallTitleFlex
                      title="Approval Rate by 3DS exemptions"
                      tooltip={
                        "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                        "Approval rate is defined as number of successfully approved transactions over total transactions"
                      }
                    />
                    <McProgressChartBlock
                      data={
                        selectedAcquirers !== null
                          ? padArray(
                              compareAndAddDummy(
                                rate3DSComplianceAcquirers,
                                rate3DSCompliance,
                                "name",
                                "value"
                              ),
                              rate3DSCompliance
                            )
                          : padArray(
                              rate3DSComplianceAcquirers,
                              rate3DSCompliance
                            )
                      }
                      palette={_variablesSixteen}
                    />
                    {dataExemptionTypesAcquirers.length > 0 ? (
                      <Button
                        text="Exemptions Details"
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "2rem",
                          width: "15rem",
                          //marginLeft:"6rem",
                        }}
                        dataBsToggle="modal"
                        dataBsTarget={"exemptionsModalAcquirers"}
                      />
                    ) : (
                      <div
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "2rem",
                          width: "15rem",
                          height: "2.6rem",
                        }}
                      ></div>
                    )}
                    <Modal
                      dataBsTarget="exemptionsModalAcquirers"
                      title={<b>Non-3DS Exemption Types</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colDefs}
                            rowData={dataExemptionTypesAcquirers}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AuthoAcquirers"
                        chartName="Non-3DS Exemption Types"
                        data={dataExemptionTypesAcquirers}
                        graphType="pie"
                        productType={productType}
                        acquirer={selectedAcquirers}
                        label={"Exemption Type"}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Transaction Share",
                            value: "transaction_share",
                          },
                          { field: "Approval Rate", value: "approval_rate" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                  </div>
                )}
                <hr />
                {macViz && (
                  <div className="mb-4">
                    <>
                      <SmallTitleFlex
                        title={
                          "Transaction share by merchant advice code (MAC)"
                        }
                        tooltip={
                          "Share of declined transactions split by MAC Present or Absent status"
                        }
                      />
                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={dataMerchantAdviseCodeDonutAcquirers}
                          palette={_variablesTwoOranges}
                          tooltipMeasureUnit="%"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          {dataMerchantAdviseCodeDonutAcquirers.length > 0 && (
                            <span>
                              <b>Transaction Share </b>
                              (within declined transactions)
                            </span>
                          )}
                          <McDonutSideLegend
                            data={dataMerchantAdviseCodeDonutAcquirers.map(
                              (el) => {
                                return { name: el.name, value: el.value };
                              }
                            )}
                            palette={_variablesTwoOranges}
                          />
                        </div>
                      </div>
                      <Modal
                        dataBsTarget="authMethodModalAcquirers"
                        title={<b>Deepdive of MAC transaction share</b>}
                        modalBody={
                          <div
                            style={{
                              width: "100%",
                              height: "30rem",
                              display: "flex",
                            }}
                          >
                            <TableAG
                              colDefs={colsDefMerchantAdviseCode}
                              rowData={dataMerchantAdviseCodeAcquirers}
                            />
                          </div>
                        }
                      >
                        <Download
                          section="AuthoAcquirers"
                          chartName="MAC"
                          data={dataMerchantAdviseCodeAcquirers}
                          graphType="pie"
                          productType={productType}
                          acquirer={selectedAcquirers}
                          label={"MAC"}
                          timeRange={
                            dateLabels[timePeriod[0]] +
                            " - " +
                            dateLabels[timePeriod[1]]
                          }
                          fields={[
                            { field: "Transaction Share", value: "value" },
                          ]}
                          iconSize="sm"
                          className="h-8 w-8"
                        />
                      </Modal>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {dataMerchantAdviseCodeDonutAcquirers.length > 0 ? (
                          <Button
                            text="Details"
                            style={{
                              fontSize: "12pt",
                              padding: "0.5rem",
                              marginTop: "3rem",
                              width: "15rem",
                            }}
                            dataBsToggle="modal"
                            dataBsTarget={"authMethodModalAcquirers"}
                          />
                        ) : (
                          <div
                            style={{
                              fontSize: "12pt",
                              padding: "0.5rem",
                              marginTop: "3rem",
                              width: "15rem",
                              height: "2.6rem",
                            }}
                          ></div>
                        )}
                      </div>
                    </>
                  </div>
                )}
              </>
            )}
            <hr />
            {declineReasonsViz && (
              <>
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"Decline reasons"}
                    tooltip={
                      "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                      "Area of each rectangle indicates the magnitude of each decline reason."
                    }
                  />
                  <Download
                    disabled={selectedAcquirers === null}
                    section="AuthoAcquirers"
                    chartName="FailureReasons"
                    data={mergedDataDeclineReasons}
                    graphType="decline"
                    productType={productType}
                    acquirer={selectedAcquirers}
                    label={"Decline Reason"}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      {
                        field: "Percentage Merchant (%)",
                        value: "percent_merchant",
                      },
                      {
                        field: "Percentage Acquirer (%)",
                        value: "percent_acquirer",
                      },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <McTreemapChart
                  data={dataDeclineReasonsAcquirers}
                  listDeclineReasons={listDeclineReasons}
                />
              </>
            )}
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthorizationAcquirers;
