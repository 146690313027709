import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatErrorForAlert,
  titleCase,
  assembleBody,
  assembleBodyWithProductType,
  filterVisibility,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitle from "../../components/Title/SmallTitle";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import LayoutBenchmarkFilter from "../../components/Layout/LayoutBenchmarkFilter";
import Switcher from "../../components/Switcher/Switcher";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import McProgressChart from "../../components/McCharts/McProgressChart";
import Kpi from "../../components/Kpi/Kpi";
import { _variablesTwoOranges } from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import McLineChart from "../../components/McCharts/McLineChart";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import Download from "../../components/Button/Download";

const PerformanceBenchmark = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    performanceDates,
    settingsConfig,
    currency,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // geography & benchmark
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState("0");

  // charts
  const [isLoadingMerchantCharts, setIsLoadingMerchantCharts] = useState(true);
  const [dataMerchantCharts, setDataMerchantCharts] = useState({});
  const [isLoadingPeerCharts, setIsLoadingPeerCharts] = useState(true);
  const [dataPeerCharts, setDataPeerCharts] = useState({});

  const [dataYoYMerchantCharts, setYoYDataMerchantCharts] = useState({});
  const [isLoadingYoYMerchantCharts, setIsLoadingYoYDataMerchantCharts] =
    useState({});
  const [dataYoYPeerCharts, setYoYDataPeerCharts] = useState({});
  const [isLoadingYoYPeerCharts, setIsLoadingYoYDataPeerCharts] = useState({});
  const [combinedYoYData, setCombinedYoYData] = useState([]);

  // YoY Filters
  const [channel, setChannel] = useState("CP");
  const [trxMeasure, setTrxMeasure] = useState("TRX");

  // YoY Data
  const [dataYoY, setDataYoY] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const settings = settingsConfig ? settingsConfig : [];

  // others
  const currencySymbol = currency === 826 ? "£" : "€";
  const [dataGuide, setDataGuide] = useState({
    trx: "trxCard",
    vol: "volCard",
    avg: "avgCard",
  });

  // product type
  const [productType, setProductType] = useState("");
  // get dates for timeperiod
  useEffect(() => {
    if (performanceDates) {
      setDateLabels(performanceDates);
    } else {
      setAlert({
        message: "Performance dates not available",
        messageType: "error",
      });
    }
  }, [performanceDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          null,
          productType
        );

        // get countries for select
        getCountries(body);

        getMerchantCharts(body);
        getPeerCharts(body);
        getYoYMerchantCharts(body);
        getYoYPeerCharts(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  // get charts data based on country
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (countries.length > 0) {
        let body = assembleBodyCountry();

        getPeerCharts(body);
        getMerchantCharts(body);
        getYoYMerchantCharts(body);
        getYoYPeerCharts(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, selectedCountries, productType]);

  useEffect(() => {
    getCombinedData();
  }, [dataYoYMerchantCharts, dataYoYPeerCharts]);

  const isLoadingAll = () => {
    setIsLoadingMerchantCharts(true);
    setIsLoadingPeerCharts(true);
    setIsLoadingYoYDataMerchantCharts(true);
    setIsLoadingYoYDataPeerCharts(true);
  };

  const assembleBodyCountry = () => {
    let body = assembleBodyWithProductType(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      null,
      productType
    );

    if (selectedCountries !== 0 && selectedCountries !== "0") {
      body.country = [selectedCountries];
    }

    return body;
  };

  const handleSelectCountry = (e) => {
    setSelectedCountries(e);
  };

  const changeDataGuide = (switchBtn) => {
    if (switchBtn === "card") {
      setDataGuide({
        trx: "trxCard",
        vol: "volCard",
        avg: "avgCard",
      });
    } else if (switchBtn === "noCard") {
      setDataGuide({
        trx: "trxNoCard",
        vol: "volNoCard",
        avg: "avgNoCard",
      });
    } else {
      setDataGuide({});
    }
  };

  useEffect(() => {
    let data = [];
    combinedYoYData.forEach((item) => {
      if (channel === "CP") {
        data.push({
          yearmonth: item.yearmonth,
          merchant:
            trxMeasure === "TRX" ? item.trxCardMerchant : item.volCardMerchant,
          peers: trxMeasure === "TRX" ? item.trxCardPeers : item.volCardPeers,
        });
      } else if (channel === "CNP") {
        data.push({
          yearmonth: item.yearmonth,
          merchant:
            trxMeasure === "TRX"
              ? item.trxNoCardMerchant
              : item.volNoCardMerchant,
          peers:
            trxMeasure === "TRX" ? item.trxNoCardPeers : item.volNoCardPeers,
        });
      }
    });

    setDataYoY(data);
  }, [channel, trxMeasure, combinedYoYData]);

  const getCountries = (body) => {
    FetchService.post("/api/dashboard/geography/performance/top", body)
      .then((res) => {
        let tempCountries = res.data.countries.sort((a, b) =>
          a.localeCompare(b)
        );
        tempCountries = tempCountries
          .map((el) => {
            // For Google hide Other value
            if (merchantName === "GOOGLE" && el !== "UK") return undefined;
            return { value: el, text: titleCase(el) };
          })
          .filter((el) => el !== undefined);
        tempCountries = [{ value: 0, text: "All countries" }, ...tempCountries];
        setCountries(tempCountries);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getMerchantCharts = (body) => {
    setIsLoadingMerchantCharts(true);

    FetchService.post("/api/dashboard/performance/benchmark/merchant", body)
      .then((res) => {
        setDataMerchantCharts({
          trxCard: res.data.pc_trx_card_present
            ? res.data.pc_trx_card_present
            : 0,
          trxNoCard: res.data.pc_trx_card_not_present
            ? res.data.pc_trx_card_not_present
            : 0,
          volCard: res.data.pc_amount_card_present
            ? res.data.pc_amount_card_present
            : 0,
          volNoCard: res.data.pc_amount_card_not_present
            ? res.data.pc_amount_card_not_present
            : 0,
          avgCard: res.data.avg_ticket_card_present
            ? res.data.avg_ticket_card_present
            : 0,
          avgNoCard: res.data.avg_ticket_card_not_present
            ? res.data.avg_ticket_card_not_present
            : 0,
        });
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantCharts(false));
  };

  const getPeerCharts = (body) => {
    setIsLoadingPeerCharts(true);

    FetchService.post("/api/dashboard/performance/benchmark/peers", body)
      .then((res) => {
        setDataPeerCharts({
          trxCard: res.data.pc_trx_card_present
            ? res.data.pc_trx_card_present
            : 0,
          trxNoCard: res.data.pc_trx_card_not_present
            ? res.data.pc_trx_card_not_present
            : 0,
          volCard: res.data.pc_amount_card_present
            ? res.data.pc_amount_card_present
            : 0,
          volNoCard: res.data.pc_amount_card_not_present
            ? res.data.pc_amount_card_not_present
            : 0,
          avgCard: res.data.avg_ticket_card_present
            ? res.data.avg_ticket_card_present
            : 0,
          avgNoCard: res.data.avg_ticket_card_not_present
            ? res.data.avg_ticket_card_not_present
            : 0,
        });
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingPeerCharts(false));
  };

  const getYoYMerchantCharts = (body) => {
    setIsLoadingYoYDataMerchantCharts(true);

    FetchService.post(
      "/api/dashboard/performance/summary/monthly-performance",
      body
    )
      .then((res) => {
        const data = res.data.map((item) => ({
          yearmonth: item.yearmonth ? item.yearmonth : "",
          trxCardMerchant: item.year_on_year_trx_card_present
            ? item.year_on_year_trx_card_present
            : 0,
          trxNoCardMerchant: item.year_on_year_trx_card_not_present
            ? item.year_on_year_trx_card_not_present
            : 0,
          volCardMerchant: item.year_on_year_amount_card_present
            ? item.year_on_year_amount_card_present
            : 0,
          volNoCardMerchant: item.year_on_year_amount_card_not_present
            ? item.year_on_year_amount_card_not_present
            : 0,
        }));
        setYoYDataMerchantCharts(data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingYoYDataMerchantCharts(false));
  };

  const getYoYPeerCharts = (body) => {
    setIsLoadingYoYDataPeerCharts(true);

    FetchService.post(
      "/api/dashboard/performance/benchmark/monthly-performance",
      body
    )
      .then((res) => {
        const data = res.data.map((item) => ({
          yearmonth: item.yearmonth ? item.yearmonth : "",
          trxCardPeers: item.year_on_year_trx_card_present
            ? item.year_on_year_trx_card_present
            : 0,
          trxNoCardPeers: item.year_on_year_trx_card_not_present
            ? item.year_on_year_trx_card_not_present
            : 0,
          volCardPeers: item.year_on_year_amount_card_present
            ? item.year_on_year_amount_card_present
            : 0,
          volNoCardPeers: item.year_on_year_amount_card_not_present
            ? item.year_on_year_amount_card_not_present
            : 0,
        }));
        setYoYDataPeerCharts(data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingYoYDataPeerCharts(false));
  };

  const getCombinedData = async () => {
    try {
      if (isLoadingYoYMerchantCharts || isLoadingYoYPeerCharts) {
        return [];
      }
      const resPeers = dataYoYPeerCharts;
      const resMerchant = dataYoYMerchantCharts;

      // Create an object where the keys are yearmonth and the values are the combined data objects
      const combinedDataObj = [...resPeers, ...resMerchant].reduce(
        (acc, obj) => {
          if (acc[obj.yearmonth]) {
            // If this yearmonth already exists in the accumulator, combine the data objects
            acc[obj.yearmonth] = {
              ...acc[obj.yearmonth],
              ...obj,
            };
          } else {
            // If this yearmonth does not exist in the accumulator, add it
            acc[obj.yearmonth] = obj;
          }

          return acc;
        },
        {}
      );

      // Convert the combined data object to an array of objects
      const combinedData = Object.values(combinedDataObj);

      setCombinedYoYData(combinedData);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  return (
    <LayoutPageContainer
      title={"Performance"}
      pages={[
        {
          title: "Summary",
          link: "/performance/home",
        },
        { title: "Geography", link: "/performance/geography" },
        { title: "Benchmark", link: "/performance/benchmark", active: true },
      ]}
      alert={alert}
      setAlert={setAlert}
      settings={settings}
    >
      <Refreshing
        conditions={[
          isLoadingMerchantCharts,
          isLoadingPeerCharts,
          isLoadingYoYMerchantCharts,
          isLoadingYoYPeerCharts,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}
      <hr />
      <FilterBoxSticky>
        <LayoutBenchmarkFilter
          options={countries}
          tooltipMerchant={
            "Merchant performance includes the overall performances based on the selected channel.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipPeer={
            "Peer performance includes all the merchants that have your same MCC in any given selected Country, based on selected channel.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          selected={selectedCountries}
          onChange={handleSelectCountry}
        />
      </FilterBoxSticky>
      {filterVisibility(
        "chart",
        settings,
        "Performance",
        "Benchmark",
        "Merchant vs Peer",
        selectedCountries
      ) && (
        <>
          <div className="d-flex justify-content-end">
            <Download
              section="PerformanceBenchmark"
              chartName="MonthlyPerformance"
              data={[dataMerchantCharts, dataPeerCharts]}
              graphType="performance"
              productType={productType}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              country={selectedCountries}
              fields={[
                { field: "Trx Card Present (%)", value: "trxCard" },
                { field: "Trx Card Not Present (%)", value: "trxNoCard" },
                { field: "Vol Card Present (%)", value: "volCard" },
                { field: "Vol Card Not Present (%)", value: "volNoCard" },
                { field: "Avg Card Present", value: "avgCard" },
                { field: "Avg Card Not Present", value: "avgNoCard" },
              ]}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>

          <Switcher
            styleClass="mx-auto"
            pages={[
              {
                title: "Card present",
                onClick: () => {
                  changeDataGuide("card");
                },
              },
              {
                title: "Card not present",
                onClick: () => {
                  changeDataGuide("noCard");
                },
              },
            ]}
          />

          <LayoutTwoColumns
            leftContent={
              <>
                <SmallTitle styleClass="mb-3 text-center">Merchant</SmallTitle>
                <div className="px-4">
                  <div className="mb-2">
                    Transactions
                    <McProgressChart
                      bar={{
                        color: _variablesTwoOranges.primaryOrange,
                        value: dataMerchantCharts[dataGuide.trx],
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    Volumes
                    <McProgressChart
                      bar={{
                        color: _variablesTwoOranges.primaryOrange,
                        value: dataMerchantCharts[dataGuide.vol],
                      }}
                    />
                  </div>

                  <Kpi
                    leftContent={
                      formatNumberFull(dataMerchantCharts[dataGuide.avg]) +
                      ` ${currencySymbol}`
                    }
                    rightContent={"Average ticket"}
                  />
                </div>
              </>
            }
            rightContent={
              <>
                <SmallTitle styleClass="mb-3 text-center">Peer</SmallTitle>
                <div className="px-4">
                  <div className="mb-2">
                    Transactions
                    <McProgressChart
                      bar={{
                        color: _variablesTwoOranges.primaryOrange,
                        value: dataPeerCharts[dataGuide.trx],
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    Volumes
                    <McProgressChart
                      bar={{
                        color: _variablesTwoOranges.primaryOrange,
                        value: dataPeerCharts[dataGuide.vol],
                      }}
                    />
                  </div>

                  <Kpi
                    leftContent={
                      formatNumberFull(dataPeerCharts[dataGuide.avg]) +
                      ` ${currencySymbol}`
                    }
                    rightContent={"Average ticket"}
                  />
                </div>
              </>
            }
            split
          />

          <hr />
        </>
      )}
      {filterVisibility(
        "chart",
        settings,
        "Performance",
        "Benchmark",
        "YoY Benchmark Growth",
        selectedCountries
      ) && (
        <>
          <div className="d-flex justify-content-between">
            <SmallTitleFlex
              title={"YoY Benchmark Growth"}
              tooltip={
                "YoY change represents the monthly performance of a given month vs. the same month of the previous year for both the merchant and peers for selected channel(s) in Transaction Volumes/Transaction Count."
              }
            />

            <Download
              section="PerformanceBenchmark"
              chartName="YoYChange"
              data={dataYoY}
              graphType="YoY"
              productType={productType}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              country={selectedCountries}
              measure={trxMeasure}
              cardSplit={channel}
              label={"Month"}
              fields={[
                { field: "Merchant (%)", value: "merchant" },
                { field: "Peers (%)", value: "peers" },
              ]}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>
          <div className="d-flex justify-content-center">
            <Switcher
              styleClass="mx-2"
              pages={[
                {
                  title: "Transaction Count",
                  onClick: () => {
                    setTrxMeasure("TRX");
                  },
                },
                {
                  title: "Transaction Volumes",
                  onClick: () => {
                    setTrxMeasure("VOLUME");
                  },
                },
              ]}
            />
            <Switcher
              styleClass="mx-2"
              pages={[
                {
                  title: "Card present",
                  onClick: () => {
                    setChannel("CP");
                  },
                },
                {
                  title: "Card not present",
                  onClick: () => {
                    setChannel("CNP");
                  },
                },
              ]}
            />
          </div>
          <div className="mb-4">
            <McLineChart data={dataYoY} />
          </div>
        </>
      )}
    </LayoutPageContainer>
  );
};

export default PerformanceBenchmark;
