import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="page-login vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
      {loading ? (
        <div>
          <div className="spinner" />
          <div className="text-404 text-center mt-2 ml-2">Loading...</div>
        </div>
      ) : (
        <div className="text-404 text-center">
          <div className="mb-5">
            <h1 className="mb-0">404</h1>
            <h6>Page not found</h6>
          </div>

          <div className="font-medium white-link" onClick={() => navigate("/")}>
            <b>Go back home</b>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotFound;
