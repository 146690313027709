import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
} from "recharts";
import {
  customTooltipLayout,
  formatNumberLocale,
  formatNumberRoundLocale,
} from "../../services/UtilsService";
import { _variablesSixAlt } from "../../styles/_variables";

const McBarChart = ({
  data,
  dataGuide,
  labels,
  height = 440,
  palette = _variablesSixAlt,
  twoYs = true,
  maxData = 0,
  tooltipMeasureUnit = "",
  rotateTicks = false,
  additionalData = [],
  autoFormatData = true,
  labelsPosition = "insidetop",
  labelColor = "white",
  domain,
}) => {
  const COLORS = Object.values(palette);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return customTooltipLayout(
        active,
        payload,
        label,
        tooltipMeasureUnit,
        additionalData,
        autoFormatData
      );
    }

    return null;
  };

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontSize={10}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const leftMargin = maxData / 1000 > 60 ? 60 : Math.round(maxData / 1000);

  const maxBarValue = Math.max(
    ...(dataGuide.barsData?.map((bar) =>
      Math.max(
        ...(data?.map((item) => Number(item[bar.dataName])).filter(Boolean) || [
          0,
        ])
      )
    ) || [0])
  );
  const threshold = domain ? 5 : maxBarValue * 0.1;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        width="100%"
        height="100%"
        data={data}
        margin={{
          top: 0,
          right: 20,
          left: 20 + leftMargin,
          bottom: rotateTicks ? 60 : 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={dataGuide.x}
          tick={rotateTicks ? <CustomizedAxisTick /> : { strokeWidth: 1 }}
          interval={rotateTicks ? 0 : "preserveEnd"}
        ></XAxis>
        <YAxis
          domain={domain}
          yAxisId="left"
          tickFormatter={(value) =>
            domain
              ? `${Math.round(formatNumberLocale(value))}%`
              : formatNumberLocale(value)
          }
          /* tickFormatter={formatNumberLocale} */
          /* allowDecimals={false} */
        >
          {labels[0] && (
            <Label
              angle={270}
              value={labels[0]}
              position={"right"}
              offset={-60 - leftMargin}
            />
          )}
        </YAxis>
        {twoYs && (
          <YAxis
            yAxisId="right"
            orientation="right"
            domain={[0, 100]}
            tickFormatter={(value) =>
              domain
                ? `${formatNumberLocale(value)}%`
                : formatNumberLocale(value)
            }
          >
            {labels[1] && (
              <Label
                angle={90}
                value={labels[1]}
                position={"left"}
                offset={-60}
              />
            )}
          </YAxis>
        )}
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={60} />

        {dataGuide.barsData?.map((bar, index) => {
          return (
            <Bar
              dataKey={bar.dataName}
              name={bar.friendlyName}
              fill={COLORS[index % COLORS.length]}
              stackId="a"
              yAxisId="left"
              key={`bar-${index}`}
            >
              <LabelList
                dataKey={bar.dataName}
                position={labelsPosition}
                fill={labelColor}
                style={{ fontSize: "14px" }}
                formatter={(value) => {
                  if (value < threshold) {
                    return "";
                  } else {
                    return domain ? value.toFixed(1) + "%" : value.toFixed(1);
                  }
                }}
              />
            </Bar>
          );
        })}

        {dataGuide.rateData && (
          <Line
            dataKey={dataGuide.rateData.dataName}
            name={dataGuide.rateData.friendlyName}
            stroke={_variablesSixAlt.primaryGreen}
            yAxisId="right"
            strokeWidth={2}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

// demo values
McBarChart.defaultProps = {
  data: [
    {
      month: "JAN 22",
      authSuccess: 6,
      authFailed: 2,
      authRate: 60,
      tot: 8,
    },
    {
      month: "FEB 22",
      authSuccess: 9,
      authFailed: 1,
      authRate: 90,
      tot: 10,
    },
    {
      month: "MAR 22",
      authSuccess: 4,
      authFailed: 4,
      authRate: 50,
      tot: 8,
    },
    {
      month: "APR 22",
      authSuccess: 3,
      authFailed: 1,
      authRate: 75,
      tot: 4,
    },
    {
      month: "MAY 22",
      authSuccess: 2,
      authFailed: 2,
      authRate: 50,
      tot: 4,
    },
    {
      month: "JUN 22",
      authSuccess: 2,
      authFailed: 2,
      authRate: 50,
      tot: 4,
    },
    {
      month: "JUL 22",
      authSuccess: 3,
      authFailed: 1,
      authRate: 75,
      tot: 4,
    },
    {
      month: "AUG 22",
      authSuccess: 3,
      authFailed: 1,
      authRate: 75,
      tot: 4,
    },
    {
      month: "SEP 22",
      authSuccess: 3,
      authFailed: 1,
      authRate: 75,
      tot: 4,
    },
    {
      month: "OCT 22",
      authSuccess: 2,
      authFailed: 2,
      authRate: 50,
      tot: 4,
    },
    {
      month: "NOV 22",
      authSuccess: 1,
      authFailed: 3,
      authRate: 25,
      tot: 4,
    },
    {
      month: "DEC 22",
      authSuccess: 2,
      authFailed: 2,
      authRate: 50,
      tot: 4,
    },
  ],
  dataGuide: {
    x: "month",
    barsData: [
      { dataName: "authSuccess", friendlyName: "Authentication success" },
      { dataName: "authFailed", friendlyName: "Authentication failed" },
    ],
    rateData: { dataName: "authRate", friendlyName: "Authentication rate" },
  },
  labels: ["Transactions (K)", "Rate (%)"],
};

export default McBarChart;
