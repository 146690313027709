const _variables = {
  textBlack: "#141413",
  boxGrey: "#efefef",
  sidebarGrey: "#f6f6f6",
  lineGrey: "#cccccc",
  chartGrey: "#e1e1e1",
  white: "#ffffff",
  primaryOrange: "#f28c00",
  primaryOrangeLight: "#f8c580",
  primaryTeal: "#4fccaf",
  primaryGreen: "#8cba2d",
  primaryYellow: "#ffc61e",
  primaryDarkOrange: "#ff661c",
  primaryRed: "#d1282d",
  primaryGrey: "#333333",
  secondaryTeal: "#b9ebdf",
  secondaryGreen: "#d4e9a6",
  secondaryOrange: "#ffba5f",
  secondaryPink: "#ffc2a4",
  secondaryRed: "#ffa476",
};

const _variablesTwoGrey = {
  primaryOrange: _variables.primaryOrange,
  chartGrey: _variables.chartGrey,
};

const _variablesTwoOranges = {
  primaryOrange: _variables.primaryOrange,
  primaryOrangeLight: _variables.primaryOrangeLight,
};

const _variablesFiveOranges = {
  primaryDarkOrange: _variables.primaryDarkOrange,
  primaryOrange: _variables.primaryOrange,
  primaryOrangeLight: _variables.primaryOrangeLight,
  secondaryOrange: _variables.secondaryOrange,
  tertiaryOrange: "#ffda99",
};

const _variablesOnlyOrangeLight = {
  primaryOrangeLight: _variables.primaryOrangeLight,
};

const _variablesOnlyOrangeLightGrey = {
  primaryOrangeLight: _variables.primaryOrangeLight,
  chartGreyA: _variables.chartGrey,
  chartGreyB: _variables.chartGrey,
};

const _variablesThreeGrey = {
  primaryOrange: _variables.primaryOrange,
  primaryOrangeLight: _variables.primaryOrangeLight,
  chartGrey: _variables.chartGrey,
};

const _variablesThreeOrangesRate = {
  primaryOrange: _variables.primaryOrange,
  primaryOrangeLight: _variables.primaryOrangeLight,
  primaryGreen: _variables.primaryGreen,
};

const _variablesFourGrey = {
  primaryOrange: _variables.primaryOrange,
  primaryOrangeLight: _variables.primaryOrangeLight,
  chartGrey: _variables.chartGrey,
  textBlack: _variables.textBlack,
};

const _variablesFour = {
  primaryDarkOrange: _variables.primaryDarkOrange,
  primaryGreen: _variables.primaryGreen,
  primaryYellow: _variables.primaryYellow,
  primaryOrange: _variables.primaryOrange,
};

const _variablesSix = {
  primaryRed: _variables.primaryRed,
  primaryDarkOrange: _variables.primaryDarkOrange,
  primaryOrange: _variables.primaryOrange,
  primaryYellow: _variables.primaryYellow,
  primaryGreen: _variables.primaryGreen,
  primaryTeal: _variables.primaryTeal,
};

const _variablesSixAlt = {
  primaryOrange: _variables.primaryOrange,
  primaryOrangeLight: _variables.primaryOrangeLight,
  primaryDarkOrange: _variables.primaryDarkOrange,
  primaryYellow: _variables.primaryYellow,
  primaryRed: _variables.primaryRed,
  primaryGreen: _variables.primaryGreen,
};

const _variablesTwelve = {
  primaryTeal: _variables.primaryTeal,
  primaryGreen: _variables.primaryGreen,
  primaryYellow: _variables.primaryYellow,
  primaryOrange: _variables.primaryOrange,
  primaryDarkOrange: _variables.primaryDarkOrange,
  primaryRed: _variables.primaryRed,
  primaryGrey: _variables.primaryGrey,
  secondaryTeal: _variables.secondaryTeal,
  secondaryGreen: _variables.secondaryGreen,
  secondaryOrange: _variables.secondaryOrange,
  secondaryPink: _variables.secondaryPink,
  secondaryRed: _variables.secondaryRed,
};

const _variablesSixteen = {
  primaryRed: _variables.primaryRed,
  primaryDarkOrange: _variables.primaryDarkOrange,
  primaryOrange: _variables.primaryOrange,
  primaryOrangeLight: _variables.primaryOrangeLight,
  primaryYellow: _variables.primaryYellow,
  primaryGreen: _variables.primaryGreen,
  primaryTeal: _variables.primaryTeal,
  secondaryRed: _variables.secondaryRed,
  secondaryOrange: _variables.secondaryOrange,
  secondaryYellow: "#ffe066",
  secondaryGreen: _variables.secondaryGreen,
  secondaryTeal: _variables.secondaryTeal,
  secondaryPink: _variables.secondaryPink,
  secondaryBlue: "#80b3ff",
  secondaryPurple: "#b580ff",
  primaryGrey: _variables.primaryGrey,
};

const _variablesTrafficLight = {
  primaryGreen: _variables.primaryGreen,
  primaryYellow: _variables.primaryYellow,
  primaryOrange: _variables.primaryOrange,
  primaryDarkOrange: _variables.primaryDarkOrange,
  primaryRed: _variables.primaryRed,
  chartGrey: _variables.chartGrey,
  textBlack: _variables.textBlack,
};

export {
  _variablesTwoGrey,
  _variablesTwoOranges,
  _variablesThreeGrey,
  _variablesThreeOrangesRate,
  _variablesFourGrey,
  _variablesFour,
  _variablesFiveOranges,
  _variablesSix,
  _variablesSixAlt,
  _variablesTwelve,
  _variablesSixteen,
  _variablesTrafficLight,
  _variablesOnlyOrangeLight,
  _variablesOnlyOrangeLightGrey,
};
export default _variables;
