import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { AccountContext } from "./services/AccountService";
import FetchService from "./services/FetchService";
import { formatErrorForAlert, filterVisibility } from "./services/UtilsService";
import Login from "./pages/Login/Login";
import AddUser from "./pages/AddUser/AddUser";
import PerformanceHome from "./pages/Performance/PerformanceHome";
import PerformanceGeography from "./pages/Performance/PerformanceGeography";
import PerformanceBenchmark from "./pages/Performance/PerformanceBenchmark";
import AuthenticationHome from "./pages/Authentication/AuthenticationHome";
import AuthenticationGeography from "./pages/Authentication/AuthenticationGeography";
import AuthenticationBenchmark from "./pages/Authentication/AuthenticationBenchmark";
import AuthorizationHome from "./pages/Authorization/AuthorizationHome";
import AuthorizationGeography from "./pages/Authorization/AuthorizationGeography";
import AuthorizationBenchmark from "./pages/Authorization/AuthorizationBenchmark";
import FraudHome from "./pages/Fraud/FraudHome";
import FraudGeography from "./pages/Fraud/FraudGeography";
import FraudBenchmark from "./pages/Fraud/FraudBenchmark";
import Analytics from "./pages/Analytics/Analytics";
import NotFound from "./pages/NotFound/NotFound";
import AuthenticationAcquirers from "./pages/Authentication/AuthenticationAcquirers";
import AuthorizationAcquirers from "./pages/Authorization/AuthorizationAcquirers";
import FraudAcquirers from "./pages/Fraud/FraudAcquirers";
import AuthorizationIssuers from "./pages/Authorization/AuthorizationIssuers";
import AuthenticationIssuers from "./pages/Authentication/AuthenticationIssuers";

import "./styles/App.css";
import TermsAndConditionsPublic from "./pages/TermsAndConditions/TermsAndConditionsPublic";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Settings from "./pages/Settings/Setting";
import EmptyPage from "./pages/EmptyPage/EmptyPage";

function App() {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const {
    sessionToken,
    userPermission,
    whoAmI,
    refreshToken,
    getDashboardVersion,
    merchantName,
    dashboardVersion,
    getPerformanceDates,
    getAutheDates,
    getAuthoDates,
    getFraudDates,
    settingsConfig,
    getDistinctGraphs,
  } = useContext(AccountContext);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const settings = settingsConfig ? settingsConfig : [];

  useEffect(() => {
    // config all requests with auth token
    FetchService.interceptors.request.clear();

    let authToken;
    if (sessionToken) {
      authToken = sessionToken;
    } else {
      authToken = localStorage.getItem("localAuthorization");
    }

    if (FetchService.interceptors.request.handlers.length === 0) {
      FetchService.interceptors.request.use((config) => {
        config.headers.Authorization = "Bearer " + authToken;
        return config;
      });
    }

    // save user role
    if (authToken) {
      whoAmI()
        .then(() => {
          setIsTokenValid(true);
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            refreshToken(authToken);
          } else {
            setIsTokenValid(false);
          }
        });
    } else {
      setIsTokenValid(false);
    }
  }, [sessionToken]);

  useEffect(() => {
    if (merchantName) {
      getDashboardVersion(merchantName);
      getPerformanceDates(merchantName);
      getAutheDates(merchantName);
      getAuthoDates(merchantName);
      getFraudDates(merchantName);
      getDistinctGraphs(
        merchantName,
        "3DS-compliance",
        "autho/approval-trends"
      );
      getDistinctGraphs(
        merchantName,
        "transactions-by-subchannel-and-approval-rate",
        "autho/approval-trends"
      );
      getDistinctGraphs(
        merchantName,
        "transactions-by-program-and-approval-rate",
        "autho/approval-trends"
      );
      getDistinctGraphs(merchantName, "3ds2-versions", "authe/authe-trends");
      getDistinctGraphs(
        merchantName,
        "authentication-methods",
        "authe/authe-trends"
      );
    }
  }, [merchantName]);

  return (
    <>
      <Routes>
        <Route
          path="/termsandconditions"
          element={<TermsAndConditionsPublic />}
        />
      </Routes>
      {isTokenValid ? (
        <main>
          <Routes>
            <Route
              path="/"
              element={
                userPermission === "admin" ? (
                  <AddUser />
                ) : userPermission === "acquirer" ? (
                  <AuthenticationHome />
                ) : filterVisibility("module", settings, "Performance") ? (
                  <PerformanceHome />
                ) : filterVisibility("module", settings, "Authentication") ? (
                  <AuthenticationHome />
                ) : filterVisibility("module", settings, "Authorization") ? (
                  <AuthorizationHome />
                ) : filterVisibility("module", settings, "Fraud") ? (
                  <FraudHome />
                ) : (
                  <EmptyPage />
                )
              }
            />
            {/* admin */}
            <Route path="/add-user" element={<AddUser />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/settings" element={<Settings />} />
            {/* performance */}
            {filterVisibility("tab", settings, "Performance", "Summary") && (
              <Route path="/performance/home" element={<PerformanceHome />} />
            )}
            {filterVisibility("tab", settings, "Performance", "Geography") && (
              <Route
                path="/performance/geography"
                element={<PerformanceGeography />}
              />
            )}
            {filterVisibility("tab", settings, "Performance", "Benchmark") && (
              <Route
                path="/performance/benchmark"
                element={<PerformanceBenchmark />}
              />
            )}
            {/* authentication */}
            {filterVisibility(
              "tab",
              settings,
              "Authentication",
              "Overview"
            ) && (
              <Route
                path="/authentication/home"
                element={<AuthenticationHome />}
              />
            )}
            {filterVisibility(
              "tab",
              settings,
              "Authentication",
              "Geography"
            ) && (
              <Route
                path="/authentication/geography"
                element={<AuthenticationGeography />}
              />
            )}
            {filterVisibility(
              "tab",
              settings,
              "Authentication",
              "Benchmark"
            ) && (
              <Route
                path="/authentication/benchmark"
                element={<AuthenticationBenchmark />}
              />
            )}
            {filterVisibility(
              "tab",
              settings,
              "Authentication",
              "Acquirers"
            ) && (
              <Route
                path="/authentication/acquirers"
                element={<AuthenticationAcquirers />}
              />
            )}
            {dashboardVersion2 &&
              filterVisibility(
                "tab",
                settings,
                "Authentication",
                "Issuers"
              ) && (
                <Route
                  path="/authentication/issuers"
                  element={<AuthenticationIssuers />}
                />
              )}
            {/* authorization */}
            {filterVisibility("tab", settings, "Authorization", "Overview") && (
              <Route
                path="/authorization/home"
                element={<AuthorizationHome />}
              />
            )}
            {filterVisibility(
              "tab",
              settings,
              "Authorization",
              "Geography"
            ) && (
              <Route
                path="/authorization/geography"
                element={<AuthorizationGeography />}
              />
            )}
            {filterVisibility(
              "tab",
              settings,
              "Authorization",
              "Benchmark"
            ) && (
              <Route
                path="/authorization/benchmark"
                element={<AuthorizationBenchmark />}
              />
            )}
            {filterVisibility(
              "tab",
              settings,
              "Authorization",
              "Acquirers"
            ) && (
              <Route
                path="/authorization/acquirers"
                element={<AuthorizationAcquirers />}
              />
            )}
            {dashboardVersion2 &&
              filterVisibility("tab", settings, "Authorization", "Issuers") && (
                <Route
                  path="/authorization/issuers"
                  element={<AuthorizationIssuers />}
                />
              )}

            {/* fraud */}
            {filterVisibility("tab", settings, "Fraud", "Overview") && (
              <Route path="/fraud/home" element={<FraudHome />} />
            )}
            {filterVisibility("tab", settings, "Fraud", "Geography") && (
              <Route path="/fraud/geography" element={<FraudGeography />} />
            )}
            {filterVisibility("tab", settings, "Fraud", "Benchmark") && (
              <Route path="/fraud/benchmark" element={<FraudBenchmark />} />
            )}
            {filterVisibility("tab", settings, "Fraud", "Acquirers") && (
              <Route path="/fraud/acquirers" element={<FraudAcquirers />} />
            )}
            {/* others */}
            <Route
              path="/termsandconditions"
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<NotFound />} />
            {dashboardVersion2 &&
              filterVisibility("tab", settings, "Authorization", "Issuers") && (
                <Route
                  path="/authorization/issuers"
                  element={<AuthorizationIssuers />}
                />
              )}
          </Routes>
        </main>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
