import { useState, createContext } from "react";
import FetchService from "./FetchService";
import { generateLastTwelveMonths } from "./UtilsService";
import { _variablesSixteen } from "../styles/_variables";

const AccountContext = createContext();

function AccountService({ children }) {
  const [sessionToken, setSessionToken] = useState(null);
  const [userPermission, setUserPermission] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [merchantName, setMerchantName] = useState(null);
  const [acquirerName, setAcquirerName] = useState(null);
  const [isMultiAcquirer, setIsMultiAcquirer] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [timePeriod, setTimePeriod] = useState([0, 11]);
  const [dashboardVersion, setDashboardVersion] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [settingsConfig, setSettingsConfig] = useState(null);

  // Fetching dates from the backend
  const [performanceDates, setPerformanceDates] = useState([]);
  const [autheDates, setAutheDates] = useState([]);
  const [authoDates, setAuthoDates] = useState([]);
  const [fraudDates, setFraudDates] = useState([]);

  const [distinctDict, setDistinctDict] = useState([]);

  const login = async (email, password) => {
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/auth/login", {
        email: email,
        password: password,
      })
        .then((res) => {
          handleSessionToken(res.data.access_token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const whoAmI = async () => {
    return await new Promise((resolve, reject) => {
      FetchService.get("/api/users/me")
        .then((res) => {
          handleUserIdentity(res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getDashboardVersion = async (merchantName) => {
    return await new Promise((resolve, reject) => {
      FetchService.get(`/api/merchants/merchant_info/${merchantName}`)
        .then((res) => {
          setDashboardVersion(res.data.dashboard_version);
          setCurrency(res.data.currency);
          setSettingsConfig(res.data.setting_config);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const refreshToken = async (authToken) => {
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/auth/token/refresh", { access_token: authToken })
        .then((res) => {
          handleSessionToken(res.data.access_token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const logout = () => {
    setSessionToken(false);
    setUserPermission("");
    setUserEmail("");
    setMerchantName("");
    localStorage.removeItem("localAuthorization");
  };

  const handleSessionToken = (sessionToken) => {
    setSessionToken(sessionToken);
    localStorage.setItem("localAuthorization", sessionToken);
  };

  const handleUserIdentity = (userIdentity) => {
    setUserPermission(
      userIdentity.is_superadmin
        ? "superadmin"
        : userIdentity.is_admin
        ? "admin"
        : userIdentity.acquirer
        ? "acquirer"
        : "user"
    );
    setUserEmail(userIdentity.email);
    setMerchantName(userIdentity.merchant);
    setAcquirerName(userIdentity.acquirer);
    setIsMultiAcquirer(userIdentity.is_multi_acquirer);
  };

  const toggleSidebarExpanded = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  // Fetching dates from the backend

  const getPerformanceDates = async (merchantName) => {
    let body = {
      merchant_name: merchantName,
    };
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/dashboard/dates/performance", body)
        .then((res) => {
          setPerformanceDates(
            generateLastTwelveMonths(res.data.max_date_customer)
          );
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getAutheDates = async (merchantName) => {
    let body = {
      merchant_name: merchantName,
    };
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/dashboard/dates/authe", body)
        .then((res) => {
          setAutheDates(generateLastTwelveMonths(res.data.max_date_customer));
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getAuthoDates = async (merchantName) => {
    let body = {
      merchant_name: merchantName,
    };
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/dashboard/dates/autho", body)
        .then((res) => {
          setAuthoDates(generateLastTwelveMonths(res.data.max_date_customer));
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getFraudDates = async (merchantName) => {
    let body = {
      merchant_name: merchantName,
    };
    return await new Promise((resolve, reject) => {
      FetchService.post("/api/dashboard/dates/fraud", body)
        .then((res) => {
          setFraudDates(generateLastTwelveMonths(res.data.max_date_customer));
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getDistinctGraphs = (merchant_name, graphName, module) => {
    FetchService.post(`/api/dashboard/${module}/distinct-${graphName}`, {
      start_date: "201010",
      end_date: "205010",
      merchant_name: merchant_name,
    })
      .then((res) => {
        const result = res.data.map((item) => item.value);
        const newGraph = { graph_name: graphName, globalMetrics: result };
        setDistinctDict((prevDistinctDict) => {
          const distinctDictLocal = [...prevDistinctDict, newGraph];
          let colorMapping = [];
          for (let i = 0; i < distinctDictLocal.length; i++) {
            colorMapping.push({
              value: result[i],
              color: _variablesSixteen[i],
            });
          }
          return distinctDictLocal;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AccountContext.Provider
      value={{
        sessionToken,
        userPermission,
        userEmail,
        merchantName,
        acquirerName,
        isMultiAcquirer,
        login,
        whoAmI,
        refreshToken,
        logout,
        sidebarExpanded,
        toggleSidebarExpanded,
        timePeriod,
        setTimePeriod,
        getDashboardVersion,
        dashboardVersion,
        settingsConfig,
        currency,
        getPerformanceDates,
        performanceDates,
        getAutheDates,
        autheDates,
        getAuthoDates,
        authoDates,
        getFraudDates,
        fraudDates,
        getDistinctGraphs,
        distinctDict,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountService, AccountContext };
