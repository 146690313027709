const McDonutSideLegend = ({
  data,
  palette,
  withHeading = false,
  heading,
  distinctValues,
}) => {
  const COLORS = Object.values(palette);

  return (
    <div>
      {withHeading && <h6 className="text-center fw-semibold">{heading}</h6>}
      {data.map((row, index) => {
        return (
          <div
            className="d-flex justify-content-start align-items-center"
            key={"donutlegend" + index + row.name}
          >
            <div
              className="square me-2 flex-shrink-0"
              style={{
                backgroundColor:
                  COLORS[
                    distinctValues && distinctValues.length > 0
                      ? (distinctValues.findIndex((el) => el === row.name) >= 0
                          ? distinctValues.findIndex((el) => el === row.name)
                          : 0) % COLORS.length
                      : index % COLORS.length
                  ],
              }}
            ></div>
            <div>{row.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default McDonutSideLegend;
