import { ReactFitty } from "react-fitty";
import { _variablesTwoOranges } from "../../styles/_variables";
import "./Kpi.css";

const Kpi = ({
  leftContent = "0",
  rightContent,
  color = _variablesTwoOranges.primaryOrange,
  autofit = true,
  styleClass = "",
}) => {
  if (typeof leftContent === "string" && leftContent.includes("K")) {
    let valueString = leftContent.slice(0, -2);
    let valueNumber = parseFloat(valueString);
    if (valueNumber < 1) leftContent = (valueNumber * 1000).toString();
  }
  return (
    <div className={"kpi-container " + styleClass}>
      <div className="row">
        <div
          className="col-6 align-self-center kpi-left-content"
          style={{ color: color }}
        >
          {autofit ? (
            <ReactFitty minSize={16} maxSize={40}>
              {leftContent}
            </ReactFitty>
          ) : (
            <>{leftContent}</>
          )}
        </div>
        <div className="col-6 align-self-center text-center kpi-right-content">
          {rightContent}
        </div>
      </div>
    </div>
  );
};

export default Kpi;
