import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatNumberLocale,
  formatErrorForAlert,
  findMax,
  assembleBody,
  assembleBodyWithProductType,
  filterVisibility,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import KpiRow from "../../components/Kpi/KpiRow";
import Switcher from "../../components/Switcher/Switcher";
import SmallTitle from "../../components/Title/SmallTitle";
import McBarChart from "../../components/McCharts/McBarChart";
import TableYoY from "../../components/TableYoY/TableYoY";
import { _variablesTwoOranges } from "../../styles/_variables";
import McYoYChange from "../../components/McCharts/McYoYChange";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Download from "../../components/Button/Download";

const PerformanceHome = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    performanceDates,
    settingsConfig,
    currency,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // charts
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [isLoadingMonthlyPerformance, setIsLoadingMonthlyPerformance] =
    useState(true);
  const [dataMonthlyPerformance, setDataMonthlyPerformance] = useState([]);
  const [dataYoYVariation, setDataYoYVariation] = useState([]);
  const [isLoadingAverageTicket, setIsLoadingAverageTicket] = useState(true);
  const [dataAverageTicket, setDataAverageTicket] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const settings = settingsConfig ? settingsConfig : [];
  const currencySymbol = currency === 826 ? "£" : "€";

  // others
  const [dataGuide, setDataGuide] = useState({
    x: "month",
    barsData: [
      { dataName: "trxCard", friendlyName: "Card present" },
      { dataName: "trxNoCard", friendlyName: "Card not present" },
    ],
  });
  const [dataGuideYoY, setDataGuideYoY] = useState("transactions");
  const [maxData, setMaxData] = useState(0);
  // product type
  const [productType, setProductType] = useState("");
  // get dates for timeperiod
  useEffect(() => {
    if (performanceDates) {
      setDateLabels(performanceDates);
    } else {
      setAlert({
        message: "Performance dates not available",
        messageType: "error",
      });
    }
  }, [performanceDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          null,
          productType
        );

        getKpis(body);
        getMonthlyPerformance(body);
        getAverageTicket(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingMonthlyPerformance(true);
    setIsLoadingAverageTicket(true);
  };

  const getKpis = (body) => {
    setIsLoadingKpis(true);

    FetchService.post(
      "/api/dashboard/performance/summary/overall-performance",
      body
    )
      .then((res) => {
        let temp = [];
        temp.push(
          formatNumberFull(res.data.sum_trx ? res.data.sum_trx / 1000 : 0)
        );
        temp.push(
          formatNumberFull(res.data.volume ? res.data.volume / 1000 : 0)
        );
        setDataKpis(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getMonthlyPerformance = (body) => {
    setIsLoadingMonthlyPerformance(true);

    FetchService.post(
      "/api/dashboard/performance/summary/monthly-performance",
      body
    )
      .then((res) => {
        let tempMonthly = [
          {
            yearmonth: "MAR 24",
            current_freq_count_amount_card_present: 1671946.472635,
            current_freq_count_trx_card_present: 9327.0,
            current_freq_count_amount_card_not_present: 229692122.63055283,
            current_freq_count_trx_card_not_present: 375999.0,
            last_yearmonth: "MAR 23",
            last_freq_count_amount_card_present: 1180075.32746569,
            last_freq_count_trx_card_present: 5947.0,
            last_freq_count_amount_card_not_present: 222752392.176355,
            last_freq_count_trx_card_not_present: 381011.0,
            year_on_year_amount_card_present: 41.68,
            year_on_year_amount_card_not_present: 3.12,
            year_on_year_trx_card_present: 56.84,
            year_on_year_trx_card_not_present: -1.32,
          },
          {
            yearmonth: "APR 24",
            current_freq_count_amount_card_present: 2041519.43531528,
            current_freq_count_trx_card_present: 9792.0,
            current_freq_count_amount_card_not_present: 218808809.70226368,
            current_freq_count_trx_card_not_present: 355521.0,
            last_yearmonth: "APR 23",
            last_freq_count_amount_card_present: 1257557.55052459,
            last_freq_count_trx_card_present: 5650.0,
            last_freq_count_amount_card_not_present: 191227503.69817686,
            last_freq_count_trx_card_not_present: 326255.0,
            year_on_year_amount_card_present: 62.34,
            year_on_year_amount_card_not_present: 14.42,
            year_on_year_trx_card_present: 73.31,
            year_on_year_trx_card_not_present: 8.97,
          },
          {
            yearmonth: "MAY 24",
            current_freq_count_amount_card_present: 2152925.94746066,
            current_freq_count_trx_card_present: 11393.0,
            current_freq_count_amount_card_not_present: 222859219.17581046,
            current_freq_count_trx_card_not_present: 351978.0,
            last_yearmonth: "MAY 23",
            last_freq_count_amount_card_present: 1616658.47388441,
            last_freq_count_trx_card_present: 7430.0,
            last_freq_count_amount_card_not_present: 217231046.17175543,
            last_freq_count_trx_card_not_present: 356765.0,
            year_on_year_amount_card_present: 33.17,
            year_on_year_amount_card_not_present: 2.59,
            year_on_year_trx_card_present: 53.34,
            year_on_year_trx_card_not_present: -1.34,
          },
          {
            yearmonth: "JUN 24",
            current_freq_count_amount_card_present: 1741496.20701986,
            current_freq_count_trx_card_present: 10340.0,
            current_freq_count_amount_card_not_present: 200957207.99295592,
            current_freq_count_trx_card_not_present: 319003.0,
            last_yearmonth: "JUN 23",
            last_freq_count_amount_card_present: 1321555.4538627,
            last_freq_count_trx_card_present: 7365.0,
            last_freq_count_amount_card_not_present: 175851959.15067047,
            last_freq_count_trx_card_not_present: 299510.0,
            year_on_year_amount_card_present: 31.78,
            year_on_year_amount_card_not_present: 14.28,
            year_on_year_trx_card_present: 40.39,
            year_on_year_trx_card_not_present: 6.51,
          },
          {
            yearmonth: "JUL 24",
            current_freq_count_amount_card_present: 1535769.6151617,
            current_freq_count_trx_card_present: 9893.0,
            current_freq_count_amount_card_not_present: 213411226.06445667,
            current_freq_count_trx_card_not_present: 349056.0,
            last_yearmonth: "JUL 23",
            last_freq_count_amount_card_present: 1562928.62699894,
            last_freq_count_trx_card_present: 8916.0,
            last_freq_count_amount_card_not_present: 189505824.48778656,
            last_freq_count_trx_card_not_present: 338732.0,
            year_on_year_amount_card_present: -1.74,
            year_on_year_amount_card_not_present: 12.61,
            year_on_year_trx_card_present: 10.96,
            year_on_year_trx_card_not_present: 3.05,
          },
          {
            yearmonth: "AUG 24",
            current_freq_count_amount_card_present: 1783315.65484923,
            current_freq_count_trx_card_present: 10522.0,
            current_freq_count_amount_card_not_present: 204734245.81645215,
            current_freq_count_trx_card_not_present: 347082.0,
            last_yearmonth: "AUG 23",
            last_freq_count_amount_card_present: 1754355.23447552,
            last_freq_count_trx_card_present: 9347.0,
            last_freq_count_amount_card_not_present: 193582583.11278138,
            last_freq_count_trx_card_not_present: 326413.0,
            year_on_year_amount_card_present: 1.65,
            year_on_year_amount_card_not_present: 5.76,
            year_on_year_trx_card_present: 12.57,
            year_on_year_trx_card_not_present: 6.33,
          },
          {
            yearmonth: "SEP 24",
            current_freq_count_amount_card_present: 1570760.25935016,
            current_freq_count_trx_card_present: 10250.0,
            current_freq_count_amount_card_not_present: 233237555.27363673,
            current_freq_count_trx_card_not_present: 386592.0,
            last_yearmonth: "SEP 23",
            last_freq_count_amount_card_present: 1822132.08492438,
            last_freq_count_trx_card_present: 8891.0,
            last_freq_count_amount_card_not_present: 207979551.9309224,
            last_freq_count_trx_card_not_present: 349459.0,
            year_on_year_amount_card_present: -13.8,
            year_on_year_amount_card_not_present: 12.14,
            year_on_year_trx_card_present: 15.29,
            year_on_year_trx_card_not_present: 10.63,
          },
          {
            yearmonth: "OCT 24",
            current_freq_count_amount_card_present: 1712909.15572931,
            current_freq_count_trx_card_present: 10026.0,
            current_freq_count_amount_card_not_present: 201883791.90458617,
            current_freq_count_trx_card_not_present: 353779.0,
            last_yearmonth: "OCT 23",
            last_freq_count_amount_card_present: 1682992.46312388,
            last_freq_count_trx_card_present: 8643.0,
            last_freq_count_amount_card_not_present: 192721669.33186993,
            last_freq_count_trx_card_not_present: 351176.0,
            year_on_year_amount_card_present: 1.78,
            year_on_year_amount_card_not_present: 4.75,
            year_on_year_trx_card_present: 16.0,
            year_on_year_trx_card_not_present: 0.74,
          },
          {
            yearmonth: "NOV 24",
            current_freq_count_amount_card_present: 1720615.67421609,
            current_freq_count_trx_card_present: 9540.0,
            current_freq_count_amount_card_not_present: 179538184.8727861,
            current_freq_count_trx_card_not_present: 316129.0,
            last_yearmonth: "NOV 23",
            last_freq_count_amount_card_present: 1796420.60228952,
            last_freq_count_trx_card_present: 9013.0,
            last_freq_count_amount_card_not_present: 177969490.3262812,
            last_freq_count_trx_card_not_present: 338354.0,
            year_on_year_amount_card_present: -4.22,
            year_on_year_amount_card_not_present: 0.88,
            year_on_year_trx_card_present: 5.85,
            year_on_year_trx_card_not_present: -6.57,
          },
          {
            yearmonth: "DEC 24",
            current_freq_count_amount_card_present: 1942094.02446371,
            current_freq_count_trx_card_present: 10640.0,
            current_freq_count_amount_card_not_present: 169040720.41927612,
            current_freq_count_trx_card_not_present: 283246.0,
            last_yearmonth: "DEC 23",
            last_freq_count_amount_card_present: 1658695.76530272,
            last_freq_count_trx_card_present: 9320.0,
            last_freq_count_amount_card_not_present: 153227355.98947522,
            last_freq_count_trx_card_not_present: 274224.0,
            year_on_year_amount_card_present: 17.09,
            year_on_year_amount_card_not_present: 10.32,
            year_on_year_trx_card_present: 14.16,
            year_on_year_trx_card_not_present: 3.29,
          },
          {
            yearmonth: "JAN 25",
            current_freq_count_amount_card_present: 1906732.88467251,
            current_freq_count_trx_card_present: 9783.0,
            current_freq_count_amount_card_not_present: 302066300.9514141,
            current_freq_count_trx_card_not_present: 466244.0,
            last_yearmonth: "JAN 24",
            last_freq_count_amount_card_present: 1697048.78942239,
            last_freq_count_trx_card_present: 9058.0,
            last_freq_count_amount_card_not_present: 294543222.0719193,
            last_freq_count_trx_card_not_present: 495425.0,
            year_on_year_amount_card_present: 12.36,
            year_on_year_amount_card_not_present: 2.55,
            year_on_year_trx_card_present: 8.0,
            year_on_year_trx_card_not_present: -5.89,
          },
          {
            yearmonth: "FEB 25",
            current_freq_count_amount_card_present: 1708566.40991083,
            current_freq_count_trx_card_present: 7956.0,
            current_freq_count_amount_card_not_present: 211468038.17432907,
            current_freq_count_trx_card_not_present: 340135.0,
            last_yearmonth: "FEB 24",
            last_freq_count_amount_card_present: 1538127.94608151,
            last_freq_count_trx_card_present: 7957.0,
            last_freq_count_amount_card_not_present: 212874398.45776898,
            last_freq_count_trx_card_not_present: 378948.0,
            year_on_year_amount_card_present: 11.08,
            year_on_year_amount_card_not_present: -0.66,
            year_on_year_trx_card_present: -0.01,
            year_on_year_trx_card_not_present: -10.24,
          },
        ].map((el) => {
          return {
            month: el.yearmonth ? el.yearmonth : "N/A",
            trxCard: el.current_freq_count_trx_card_present
              ? el.current_freq_count_trx_card_present / 1000
              : 0,
            trxNoCard: el.current_freq_count_trx_card_not_present
              ? el.current_freq_count_trx_card_not_present / 1000
              : 0,
            volCard: el.current_freq_count_amount_card_present
              ? el.current_freq_count_amount_card_present / 1000
              : 0,
            volNoCard: el.current_freq_count_amount_card_not_present
              ? el.current_freq_count_amount_card_not_present / 1000
              : 0,
          };
        });
        setDataMonthlyPerformance(tempMonthly);

        // find max for better chart visualization
        setMaxData(findMax(tempMonthly));

        let tempYoY = res.data.map((el) => {
          return {
            month: el.yearmonth ? el.yearmonth : "N/A",
            trxCard: el.year_on_year_trx_card_present
              ? el.year_on_year_trx_card_present
              : 0,
            trxNoCard: el.year_on_year_trx_card_not_present
              ? el.year_on_year_trx_card_not_present
              : 0,
            volCard: el.year_on_year_amount_card_present
              ? el.year_on_year_amount_card_present
              : 0,
            volNoCard: el.year_on_year_amount_card_not_present
              ? el.year_on_year_amount_card_not_present
              : 0,
          };
        });
        setDataYoYVariation(tempYoY);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMonthlyPerformance(false));
  };

  const getAverageTicket = (body) => {
    setIsLoadingAverageTicket(true);

    FetchService.post("/api/dashboard/performance/summary/average-ticket", body)
      .then((res) => {
        let temp = [];
        temp.push(
          formatNumberFull(
            res.data.avg_ticket_card_present
              ? res.data.avg_ticket_card_present
              : 0
          )
        );
        temp.push(
          formatNumberFull(
            res.data.avg_ticket_card_not_present
              ? res.data.avg_ticket_card_not_present
              : 0
          )
        );
        setDataAverageTicket(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAverageTicket(false));
  };

  const changeDataGuide = (switchBtn) => {
    if (switchBtn === "transactions") {
      setDataGuide({
        x: "month",
        barsData: [
          { dataName: "trxCard", friendlyName: "Card present" },
          { dataName: "trxNoCard", friendlyName: "Card not present" },
        ],
      });
    } else if (switchBtn === "volumes") {
      setDataGuide({
        x: "month",
        barsData: [
          { dataName: "volCard", friendlyName: "Card present" },
          { dataName: "volNoCard", friendlyName: "Card not present" },
        ],
      });
    } else {
      setDataGuide({});
    }

    setDataGuideYoY(switchBtn);
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  return (
    <LayoutPageContainer
      title={"Performance"}
      pages={[
        {
          title: "Summary",
          link: "/performance/home",
          active: true,
        },
        { title: "Geography", link: "/performance/geography" },
        { title: "Benchmark", link: "/performance/benchmark" },
      ]}
      alert={alert}
      setAlert={setAlert}
      settings={settings}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingMonthlyPerformance,
          isLoadingAverageTicket,
        ]}
        marginLeft={sidebarExpanded}
      />
      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />
      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}
      <hr />
      {filterVisibility(
        "chart",
        settings,
        "Performance",
        "Summary",
        "Overall performance"
      ) && (
        <>
          <SmallTitle>Overall performance</SmallTitle>
          <KpiRow
            kpis={[
              {
                leftContent:
                  formatNumberLocale(dataKpis[0] ? dataKpis[0] : "0") + " K",
                righContent: "Transactions",
              },
              {
                leftContent:
                  formatNumberLocale(dataKpis[1] ? dataKpis[1] : "0") +
                  ` K${currencySymbol}`,
                righContent: "Volumes",
              },
            ]}
          />
          <hr />
        </>
      )}
      {filterVisibility(
        "chart",
        settings,
        "Performance",
        "Summary",
        "Monthly performance"
      ) && (
        <>
          <SmallTitleFlex
            title={"Monthly performance"}
            tooltip={
              "Number of transactions and volumes (spend) by channel: card present (physical in-store) and card not present (online)."
            }
          />
          <div className="d-flex justify-content-between mr-4">
            <Switcher
              pages={[
                {
                  title: "Transactions",
                  onClick: () => {
                    changeDataGuide("transactions");
                  },
                },
                {
                  title: "Volumes",
                  onClick: () => {
                    changeDataGuide("volumes");
                  },
                },
              ]}
            />
            <div className="mr-4">
              <Download
                section="PerformanceSummary"
                chartName="MonthlyPerformance"
                data={dataMonthlyPerformance}
                graphType="barChart"
                productType={productType}
                label={"Time Period"}
                field1={"Transactions Card Present"}
                value1={"trxCard"}
                field2={"Transactions Card Not Present"}
                value2={"trxNoCard"}
                field3={"Volume Card Present"}
                value3={"volCard"}
                field4={"Volume Card Not Present"}
                value4={"volNoCard"}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>
          </div>
          <div className="mb-5">
            <McBarChart
              data={dataMonthlyPerformance}
              dataGuide={dataGuide}
              labels={[
                dataGuideYoY == "transactions"
                  ? "Transactions (K)"
                  : `Volumes (K${currencySymbol})`,
              ]}
              twoYs={false}
              maxData={maxData}
              tooltipMeasureUnit={
                dataGuideYoY == "transactions" ? " K" : ` K${currencySymbol}`
              }
            />
          </div>
        </>
      )}
      {filterVisibility(
        "chart",
        settings,
        "Performance",
        "Summary",
        "YoY Change"
      ) && (
        <>
          <div className="d-flex justify-content-between mr-4">
            <SmallTitleFlex
              title={"YoY Change"}
              tooltip={
                "YoY Change represents the monthly performance of a given month vs the same month of the previous year."
              }
            />
            <div className="mr-4">
              <Download
                section="PerformanceSummary"
                chartName="YoY Change"
                data={dataYoYVariation}
                graphType="barChart"
                productType={productType}
                label={"Time Period"}
                field1={"Var% Transactions Card Present"}
                value1={"trxCard"}
                field2={"Var% Transactions Card Not Present"}
                value2={"trxNoCard"}
                field3={"Var% Volume Card Present"}
                value3={"volCard"}
                field4={"Var% Volume Card Not Present"}
                value4={"volNoCard"}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>
          </div>
          {/* <TableYoY data={dataYoYVariation} dataGuide={dataGuideYoY} /> */}
          <McYoYChange data={dataYoYVariation} />
          <hr />
        </>
      )}
      {filterVisibility(
        "chart",
        settings,
        "Performance",
        "Summary",
        "Average ticket"
      ) && (
        <>
          <SmallTitleFlex
            title={"Average ticket"}
            tooltip={
              "Average ticket is calculated as: volumes (spend) divided by transactions."
            }
          />
          <KpiRow
            kpis={[
              {
                leftContent:
                  formatNumberLocale(
                    dataAverageTicket[0] ? dataAverageTicket[0] : "0"
                  ) + ` ${currencySymbol}`,
                righContent: (
                  <div>
                    <b>Average ticket</b>
                    <br />
                    Card present
                  </div>
                ),
              },
              {
                leftContent:
                  formatNumberLocale(
                    dataAverageTicket[1] ? dataAverageTicket[1] : "0"
                  ) + ` ${currencySymbol}`,
                righContent: (
                  <div>
                    <b>Average ticket</b>
                    <br />
                    Card not present
                  </div>
                ),
                color: _variablesTwoOranges.primaryOrangeLight,
              },
            ]}
          />
        </>
      )}
    </LayoutPageContainer>
  );
};

export default PerformanceHome;
