import McProgressChart from "./McProgressChart";

const McProgressChartBlock = ({ data, palette, distinctValues }) => {
  const COLORS = Object.values(palette);

  return (
    <>
      {data.map((row, index) => {
        return (
          <div
            key={"bar" + index + row.name}
            style={{ visibility: row.visibility ? row.visibility : "visible" }}
          >
            <div>{row.name ? row.name : "-"}</div>
            <McProgressChart
              bar={{
                value: row.value,
                color:
                  COLORS[
                    distinctValues && distinctValues.length > 0
                      ? (distinctValues.findIndex((el) => el === row.name) >= 0
                          ? distinctValues.findIndex((el) => el === row.name)
                          : 0) % COLORS.length
                      : index % COLORS.length
                  ],
              }}
              small
            />
          </div>
        );
      })}
    </>
  );
};

export default McProgressChartBlock;
