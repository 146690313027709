import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  generateLastMonths,
  convertMonthLabelForApi,
  formatNumberFull,
  formatErrorForAlert,
  titleCase,
  sortTicketRanges,
  assembleBody,
  assembleBodyWithProductType,
  assembleBodyFinal,
  findMax,
  fill3DSMissingData,
  compareAndAddDummy,
  mergeData,
  filterVisibility,
  filterMetrics,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import Kpi from "../../components/Kpi/Kpi";
import KpiBig from "../../components/Kpi/KpiBig";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import LayoutGeographyFilter from "../../components/Layout/LayoutGeographyFilter";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import McBarChartTrafficLight from "../../components/McCharts/McBarChartTrafficLight";
import { _variablesTwoOranges } from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import LayoutFilter from "../../components/Layout/LayoutFilter";
import Switcher from "../../components/Switcher/Switcher";
import McBarChartStacked from "../../components/McCharts/McBarChartStacked";
import McBarChart from "../../components/McCharts/McBarChart";
import Download from "../../components/Button/Download";

const padArray = (arrayToDisplay, arrayToCompare) => {
  let paddedArrayToDisplay = [...arrayToDisplay];

  if (arrayToCompare.length > arrayToDisplay.length) {
    for (
      let index = 0;
      index < arrayToCompare.length - arrayToDisplay.length;
      index++
    ) {
      paddedArrayToDisplay.push({
        name: "",
        value: null,
        visibility: "hidden",
      });
    }
  }

  return paddedArrayToDisplay;
};

const padArrayProgramAndSubchannel = (arrayToDisplay, arrayToCompare) => {
  let paddedArrayToDisplay = [...arrayToDisplay];

  if (arrayToCompare.length > arrayToDisplay.length) {
    for (
      let index = 0;
      index < arrayToCompare.length - arrayToDisplay.length;
      index++
    ) {
      paddedArrayToDisplay.push({
        subchannel: arrayToCompare[index].subchannel,
        rate_3ds: 0,
        rate_non_3ds: 0,
      });
    }
  }

  return paddedArrayToDisplay;
};

const padArrayProgram = (arrayToDisplay, arrayToCompare) => {
  let paddedArrayToDisplay = [...arrayToDisplay];

  if (arrayToCompare.length > arrayToDisplay.length) {
    for (
      let index = 0;
      index < arrayToCompare.length - arrayToDisplay.length;
      index++
    ) {
      paddedArrayToDisplay.push({
        label: arrayToCompare[index].label,
        value: 0,
      });
    }
  }

  return paddedArrayToDisplay;
};

const FraudGeography = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    fraudDates,
    settingsConfig,
    currency,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const settings = settingsConfig ? settingsConfig : [];
  const currencySymbol = currency === 826 ? "£" : "€";

  //const [timePeriod, setTimePeriod] = useState([0, 11]);
  const [dateLabels, setDateLabels] = useState([]);

  // geography & benchmark
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  // chart
  const [isLoadingTopFifteen, setIsLoadingTopFifteen] = useState(true);
  const [dataTopFifteenTransactions, setDataTopFifteenTransactions] = useState(
    []
  );
  const [dataTopFifteenRate, setDataTopFifteenRate] = useState([]);
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState({});
  const [dataKpisAvg, setDataKpisAvg] = useState({});
  const [isLoadingByTicket, setIsLoadingByTicket] = useState(true);
  const [dataByTicket, setDataByTicket] = useState([]);
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);

  // charts countries
  const [dataKpisCountries, setDataKpisCountries] = useState([]);
  const [dataByTicketCountries, setDataByTicketCountries] = useState([]);
  const [dataByProgramCountries, setDataByProgramCountries] = useState([]);
  // product type
  const [productType, setProductType] = useState("");
  const [transactionType, setTransactionType] = useState("count");

  // subchannel and program
  const [isLoadingBySubchannelAndProgram, setIsLoadingBySubchannelAndProgram] =
    useState(true);
  const [dataBySubchannelAndProgram, setDataBySubchannelAndProgram] = useState(
    []
  );
  const [
    dataBySubchannelAndProgramCountries,
    setDataBySubchannelAndProgramCountries,
  ] = useState([]);
  //top 5
  const [maxReasonCodeSplit, setMaxReasonCodeSplit] = useState([]);
  const [maxReasonCodeSplitCountries, setMaxReasonCodeSplitCountries] =
    useState([]);
  const [dataTopFiveReasonCodeSplit, setDataTopFiveReasonCodeSplit] = useState(
    []
  );
  const [
    dataTopFiveReasonCodeSplitCountries,
    setDataTopFiveReasonCodeSplitCountries,
  ] = useState([]);
  const [isLoadingTopFiveReasonCodeSplit, setIsLoadingTopFiveReasonCodeSplit] =
    useState(true);
  //merged data
  const [mergedDataByTicketRange, setMergedDataByTicketRange] = useState([]);
  const [
    mergedDataBySubchannelAndProgram,
    setMergedDataBySubchannelAndProgram,
  ] = useState([]);
  const [mergedDataByReasonCode, setMergedDataByReasonCode] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    if (fraudDates) {
      setDateLabels(fraudDates);
    } else {
      setAlert({
        message: "Fraud dates not available",
        messageType: "error",
      });
    }
  }, [fraudDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();
    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType,
          transactionType === "count" ? false : true
        );

        // get countries for select
        getCountries(body);

        getTopFifteen(body);
        getKpis(body);
        getKpisAvg(body);
        // special body for the filter
        body["is_volume"] = transactionType === "count" ? false : true;

        getByTicket(body);
        getByProgram(body);
        getBySubchannelAndProgram(body);
        getTopFiveReasonCodeSplit(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  // get charts data based on country
  useEffect(() => {
    if (selectedCountries.length > 0) {
      let body = assembleBodyCountry();

      getKpis(body, true);

      // special body for the filter
      body["is_volume"] = transactionType === "count" ? false : true;

      getByTicket(body, true);
      getByProgram(body, true);
      getBySubchannelAndProgram(body, true);
      getTopFiveReasonCodeSplit(body, true);
    } else {
      clearChartsCountries();
    }
  }, [timePeriod, dateLabels, selectedCountries, productType]);

  // get charts data based on transaction type filter
  useEffect(() => {
    setIsLoadingByProgram(true);
    setIsLoadingByTicket(true);
    setIsLoadingTopFifteen(true);

    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      // special body for the filter
      let bodyFilter = assembleBodyFinal(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        acquirerName,
        productType,
        transactionType === "count" ? false : true
      );
      getByTicket(bodyFilter);
      getByProgram(bodyFilter);
      getBySubchannelAndProgram(bodyFilter);
      getTopFifteen(bodyFilter);
      getKpisAvg(bodyFilter);

      if (selectedCountries?.length > 0) {
        let body = assembleBodyCountry();
        // special body for the filter
        body["is_volume"] = transactionType === "count" ? false : true;
        getByTicket(body, true);
        getByProgram(body, true);
        getBySubchannelAndProgram(body, true);
        getTopFifteen(body);
        getKpisAvg(body);
      }
    }
  }, [transactionType]);

  const isLoadingAll = () => {
    setIsLoadingTopFifteen(true);
    setIsLoadingKpis(true);
    setIsLoadingByTicket(true);
    setIsLoadingByProgram(true);
    setIsLoadingBySubchannelAndProgram(true);
    setIsLoadingTopFiveReasonCodeSplit(true);
  };

  const assembleBodyCountry = () => {
    return {
      merchant_name: merchantName,
      start_date: convertMonthLabelForApi(dateLabels[timePeriod[0]]),
      end_date: convertMonthLabelForApi(dateLabels[timePeriod[1]]),
      country: selectedCountries.map((el) => el.value),
      product_type: productType,
    };
  };

  const handleSelectCountry = (e) => {
    setSelectedCountries(e);
  };

  const getCountries = (body) => {
    FetchService.post("/api/dashboard/geography/fraud/top", body)
      .then((res) => {
        let tempCountries = res.data.countries.sort((a, b) =>
          a.localeCompare(b)
        );
        tempCountries = tempCountries.map((el) => {
          return { value: el, label: titleCase(el) };
        });

        setCountries(tempCountries);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const clearChartsCountries = () => {
    setDataKpisCountries({});
    setDataByTicketCountries([]);
    setDataByProgramCountries([]);
    setDataBySubchannelAndProgramCountries([]);
    setMaxReasonCodeSplitCountries([]);
    setDataTopFiveReasonCodeSplitCountries([]);
  };

  const getTopFifteen = (body) => {
    setIsLoadingTopFifteen(true);

    FetchService.post(
      "/api/dashboard/fraud/geography/top-15-countries-by-transactions",
      body
    )
      .then((res) => {
        let tempTransactions = [];
        let tempRate = [];

        res.data.forEach((el) => {
          tempTransactions.push({
            country: el.country ? titleCase(el.country) : "",
            value: el.sum_trx ? el.sum_trx / 1000 : 0,
          });
          tempRate.push({
            country: el.country ? titleCase(el.country) : "",
            value: el.fraud_rate ? el.fraud_rate : 0,
            avg: el.above_average ? el.above_average : 0,
          });
        });

        setDataTopFifteenTransactions(tempTransactions);
        setDataTopFifteenRate(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFifteen(false));
  };

  const getKpis = (body, isCountries) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/fraud/geography/performance", body)
      .then((res) => {
        isCountries ? setDataKpisCountries(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getKpisAvg = (body) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/fraud/geography/performance", body)
      .then((res) => {
        setDataKpisAvg(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getByTicket = (body, isCountries) => {
    setIsLoadingByTicket(true);

    FetchService.post(
      "/api/dashboard/fraud/geography/fraud-rate-by-ticket-range",
      body
    )
      .then((res) => {
        res.data = filterMetrics(
          res.data,
          settings,
          "Fraud",
          "Geography",
          "Fraud rate by ticket range",
          "label",
          selectedCountries
        );
        res.data.forEach((el) => (el.label = el.label + " " + currencySymbol));
        isCountries
          ? setDataByTicketCountries(sortTicketRanges(res.data, currencySymbol))
          : setDataByTicket(sortTicketRanges(res.data, currencySymbol));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicket(false));
  };

  const getByProgram = (body, isCountries) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      "/api/dashboard/fraud/geography/fraud-rate-by-program",
      body
    )
      .then((res) => {
        isCountries
          ? setDataByProgramCountries(fill3DSMissingData(res.data))
          : setDataByProgram(fill3DSMissingData(res.data));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const getTopFiveReasonCodeSplit = (body, isCountries) => {
    setIsLoadingTopFiveReasonCodeSplit(true);

    FetchService.post(
      "/api/dashboard/fraud/geography/top-5-fraud-reason-code",
      body
    )
      .then((res) => {
        res.data = filterMetrics(
          res.data,
          settings,
          "Fraud",
          "Geography",
          "Fraud transaction share by reason code",
          "label",
          selectedCountries
        );
        let sortData = res.data.sort((a, b) => b.percent - a.percent);
        if (isCountries) {
          setMaxReasonCodeSplitCountries(findMax(sortData));
          setDataTopFiveReasonCodeSplitCountries(res.data);
        } else {
          setMaxReasonCodeSplit(findMax(sortData));
          setDataTopFiveReasonCodeSplit(res.data);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFiveReasonCodeSplit(false));
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  const getBySubchannelAndProgram = (body, isCountries) => {
    setIsLoadingBySubchannelAndProgram(true);

    FetchService.post(
      "/api/dashboard/fraud/geography/fraud-rate-by-subchannel-and-program",
      body
    )
      .then((res) => {
        res.data = filterMetrics(
          res.data,
          settings,
          "Fraud",
          "Geography",
          "Fraud rate by subchannel & program",
          "subchannel",
          selectedCountries
        );
        isCountries
          ? setDataBySubchannelAndProgramCountries(res.data)
          : setDataBySubchannelAndProgram(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannelAndProgram(false));
  };

  const handleSelectedTransactionType = (e) => {
    setTransactionType(e);
  };

  function mergeTopFifteen(array1, array2) {
    return array1.map((item1) => {
      const item2 = array2.find((item2) => item2.country === item1.country);
      return item2
        ? {
            ...item1,
            authRate: item2.value,
          }
        : item1;
    });
  }

  useEffect(() => {
    setMergedDataByTicketRange(
      mergeData(
        dataByTicket,
        compareAndAddDummy(
          dataByTicketCountries,
          dataByTicket,
          "label",
          "value"
        ),
        [{ source: "value", target: "value_all" }],
        [{ source: "value", target: "value_country" }],
        "label"
      )
    );
  }, [dataByTicket, dataByTicketCountries]);

  useEffect(() => {
    setMergedDataByTicketRange(
      mergeData(
        dataByTicket,
        compareAndAddDummy(
          dataByTicketCountries,
          dataByTicket,
          "label",
          "value"
        ),
        [{ source: "value", target: "value_all" }],
        [{ source: "value", target: "value_country" }],
        "label"
      )
    );
  }, [dataByTicket, dataByTicketCountries]);

  useEffect(() => {
    setMergedDataByReasonCode(
      mergeData(
        dataTopFiveReasonCodeSplit,
        compareAndAddDummy(
          dataTopFiveReasonCodeSplitCountries,
          dataTopFiveReasonCodeSplit,
          "label",
          "percent"
        ),
        [{ source: "percent", target: "percent_all" }],
        [{ source: "percent", target: "percent_country" }],
        "label"
      )
    );
  }, [dataTopFiveReasonCodeSplit, dataTopFiveReasonCodeSplitCountries]);

  useEffect(() => {
    setMergedDataBySubchannelAndProgram(
      mergeData(
        dataBySubchannelAndProgram,
        compareAndAddDummy(
          dataBySubchannelAndProgramCountries,
          dataBySubchannelAndProgram,
          "subchannel",
          "rate_3ds",
          "rate_non_3ds"
        ),
        [
          { source: "rate_3ds", target: "rate_3ds_all" },
          { source: "rate_non_3ds", target: "rate_non_3ds_all" },
        ],
        [
          { source: "rate_3ds", target: "rate_3ds_country" },
          { source: "rate_non_3ds", target: "rate_non_3ds_country" },
        ],
        "subchannel"
      )
    );
  }, [dataBySubchannelAndProgram, dataBySubchannelAndProgramCountries]);

  // Visibility settings
  const getVisibility = (graphName) => {
    return filterVisibility(
      "chart",
      settings,
      "Fraud",
      "Geography",
      graphName,
      selectedCountries
    );
  };

  const top15Vix = getVisibility("Top 15 countries by transactions");
  const fraudRateViz = getVisibility("Fraud Rate");
  const fraudTrxViz = getVisibility("Fraudulent transactions");
  const fraudAmountViz = getVisibility("Fraudulent amount");
  const avgGenuineTicketViz = getVisibility("Average genuine ticket");
  const avgFraudTicketViz = getVisibility("Average fraudulent ticket");
  const ticketRangeVix = getVisibility("Fraud rate by ticket range");
  const subChannelProgramVix = getVisibility(
    "Fraud rate by subchannel & program"
  );
  const programVix = getVisibility("Fraud rate by program");
  const trxShareReasonCodeVix = getVisibility(
    "Fraud transaction share by reason code"
  );

  return (
    <LayoutPageContainer
      title={"Fraud"}
      pages={[
        {
          title: "Overview",
          link: "/fraud/home",
        },
        { title: "Geography", link: "/fraud/geography", active: true },
        { title: "Benchmark", link: "/fraud/benchmark" },
        { title: "Acquirers", link: "/fraud/acquirers" },
      ]}
      alert={alert}
      setAlert={setAlert}
      settings={settings}
    >
      <Refreshing
        conditions={[
          isLoadingTopFifteen,
          isLoadingKpis,
          isLoadingByTicket,
          isLoadingByProgram,
          isLoadingBySubchannelAndProgram,
          isLoadingTopFiveReasonCodeSplit,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />
      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}
      <hr />
      {/*  <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          isShared={true}
          sharedState={transactionType}
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
              value: "count",
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
              value: "volume",
            },
          ]}
        />
      </div> */}
      {top15Vix && (
        <>
          <div className="d-flex justify-content-between">
            <SmallTitleFlex
              title={"Top 15 countries by transactions"}
              tooltip={
                "Top 15 issuing countries listed in descending order by the number of transactions.<br /><br />" +
                "For each issuing country, the graph on the right shows the corresponding Fraud rate, calculated as: incidence of fraudulent transactions on total transactions.<br /><br />" +
                "100 bps = 1%"
              }
            />
            <Download
              section="FraudGeography"
              chartName="Top 15 countries by transactions"
              data={mergeTopFifteen(
                dataTopFifteenTransactions,
                dataTopFifteenRate
              )}
              graphType="barChart"
              productType={productType}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              label={"Country"}
              field1={transactionType === "count" ? "Transactions" : "Volumes"}
              value1={"value"}
              field2={"Approval Rate (%)"}
              value2={"authRate"}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>
          <Switcher
            isShared={true}
            sharedState={transactionType}
            pages={[
              {
                title: "Transactions",
                onClick: () => {
                  setTransactionType("count");
                },
                value: "count",
              },
              {
                title: "Volumes",
                onClick: () => {
                  setTransactionType("volume");
                },
                value: "volume",
              },
            ]}
          />
          <LayoutTwoColumns
            leftContent={
              <>
                <div className="text-center mb-3">
                  {transactionType === "count"
                    ? "Transactions (K)"
                    : `Volumes (K${currencySymbol})`}
                </div>
                <McBarChartVertical
                  background={false}
                  data={dataTopFifteenTransactions}
                  dataGuide={{
                    y: "country",
                    barsData:
                      transactionType === "count"
                        ? [{ dataName: "value", friendlyName: "Transactions" }]
                        : [{ dataName: "value", friendlyName: "Volumes" }],
                  }}
                  tooltipMeasureUnit={
                    transactionType === "count" ? " K" : ` K${currencySymbol}`
                  }
                />
              </>
            }
            rightContent={
              <>
                <div className="text-center mb-3">Fraud rate (bps)</div>
                <McBarChartVertical
                  data={dataTopFifteenRate}
                  dataGuide={{
                    y: "country",
                    barsData: [{ dataName: "value", friendlyName: "Rate" }],
                  }}
                  labels={["Rate (bps)"]}
                  legend
                  tooltipMeasureUnit=" bps"
                  avg={dataKpisAvg.fraud_rate}
                />
              </>
            }
          />
        </>
      )}

      <hr />

      <FilterBoxSticky>
        <LayoutGeographyFilter
          options={countries}
          tooltipOverall={
            "Overall performance includes all the countries. Fraud rate is calculated as: incidence of fraudulent transactions on total transactions.<br /><br />" +
            "Average genuine/fraudulent tickets are calculated as: volumes (spend) divided by transactions.<br /><br />" +
            "100 bps = 1%"
          }
          tooltipCountry={
            "Country performance includes the selected countries.<br /><br />" +
            "Average genuine/fraudulent tickets are calculated as: volumes (spend) divided by transactions.<br /><br />" +
            "100 bps = 1%"
          }
          selected={selectedCountries}
          onChange={handleSelectCountry}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <div>
            {fraudRateViz && (
              <KpiBig
                bottomContent={
                  (dataKpis.fraud_rate
                    ? formatNumberFull(dataKpis.fraud_rate)
                    : 0) + " bps"
                }
                topContent={"Fraud rate"}
                styleClass="mb-3"
              />
            )}
            {fraudTrxViz && (
              <Kpi
                leftContent={
                  (dataKpis.fraud_trx
                    ? dataKpis.fraud_trx >= 1000
                      ? formatNumberFull(dataKpis.fraud_trx / 1000)
                      : dataKpis.fraud_trx / 1000
                    : 0) + " K"
                }
                rightContent={"Fraudulent transactions"}
                styleClass="mb-3"
              />
            )}
            {fraudAmountViz && (
              <Kpi
                leftContent={
                  (dataKpis.fraud_amount
                    ? formatNumberFull(dataKpis.fraud_amount)
                    : 0) +
                  " " +
                  currencySymbol
                }
                rightContent={"Fraudulent amount"}
                styleClass="mb-3"
              />
            )}
            {avgGenuineTicketViz && (
              <Kpi
                leftContent={
                  (dataKpis.avg_genuine_ticket
                    ? formatNumberFull(dataKpis.avg_genuine_ticket)
                    : 0) +
                  " " +
                  currencySymbol
                }
                rightContent={"Average genuine ticket"}
                color={_variablesTwoOranges.primaryOrangeLight}
                styleClass="mb-3"
              />
            )}
            {avgFraudTicketViz && (
              <Kpi
                leftContent={
                  (dataKpis.avg_fraud_ticket
                    ? formatNumberFull(dataKpis.avg_fraud_ticket)
                    : 0) +
                  " " +
                  currencySymbol
                }
                rightContent={"Average fraudulent ticket"}
                styleClass="mb-3"
              />
            )}

            <hr style={{ marginBottom: "5.6rem" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <Switcher
                isShared={true}
                sharedState={transactionType}
                pages={[
                  {
                    title: "Transactions",
                    onClick: () => {
                      setTransactionType("count");
                    },
                    value: "count",
                  },
                  {
                    title: "Volumes",
                    onClick: () => {
                      setTransactionType("volume");
                    },
                    value: "volume",
                  },
                ]}
              />
            </div>
            {ticketRangeVix && (
              <>
                <SmallTitleFlex
                  title={"Fraud rate by ticket range"}
                  tooltip={
                    "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each ticket range.<br /><br />" +
                    "100 bps = 1%"
                  }
                />
                <McBarChartTrafficLight
                  height={
                    60 +
                    (dataByTicket.length > 0 ? dataByTicket.length * 50 : 300)
                  }
                  data={dataByTicket}
                  dataGuide={{
                    y: "label",
                    barsData: [
                      { dataName: "value", friendlyName: "Fraud rate" },
                    ],
                  }}
                  tooltipMeasureUnit=" bps"
                  marginLeft
                />
              </>
            )}
            <hr />
            {dashboardVersion2 && subChannelProgramVix ? (
              <>
                <SmallTitleFlex
                  title={"Fraud rate by subchannel & program"}
                  tooltip={
                    "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program and subchannel.<br /><br />" +
                    "100 bps = 1%"
                  }
                />
                <McBarChartStacked
                  height={
                    120 +
                    (dataBySubchannelAndProgram.length > 0
                      ? dataBySubchannelAndProgram.length * 60
                      : 120)
                  }
                  data={dataBySubchannelAndProgram}
                  dataGuide={{
                    y: "subchannel",
                    barsData: [
                      {
                        firstBar: "rate_3ds",
                        secondBar: "rate_non_3ds",
                        friendlyName: "Fraud rate by subchannel and program",
                      },
                    ],
                  }}
                  tooltipMeasureUnit="bps"
                  marginLeft
                  background={false}
                />
              </>
            ) : programVix ? (
              <>
                <SmallTitleFlex
                  title={"Fraud rate by program"}
                  tooltip={
                    "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program.<br /><br />" +
                    "100 bps = 1%"
                  }
                />
                <McBarChartTrafficLight
                  height={
                    60 +
                    (dataByProgram.length > 0 ? dataByProgram.length * 60 : 120)
                  }
                  data={dataByProgram}
                  dataGuide={{
                    y: "label",
                    barsData: [
                      { dataName: "value", friendlyName: "Fraud rate" },
                    ],
                  }}
                  tooltipMeasureUnit="bps"
                  marginLeft
                />
              </>
            ) : null}
            {dashboardVersion2 && trxShareReasonCodeVix && (
              <>
                <hr />
                <SmallTitleFlex
                  title={"Fraud transaction share by reason code"}
                  tooltip={
                    "Fraud transactions share split over the different fraud reason codes"
                  }
                />
                <div className="mr-9">
                  <McBarChartVertical
                    data={dataTopFiveReasonCodeSplit}
                    dataGuide={{
                      y: "label",
                      barsData: [
                        { dataName: "percent", friendlyName: "Percentage" },
                      ],
                    }}
                    height={400}
                    labels={["Fraud Categories"]}
                    twoYs={false}
                    maxData={maxReasonCodeSplit}
                    isPercentage={true}
                  />
                </div>
              </>
            )}
          </div>
        }
        rightContent={
          <div>
            {fraudRateViz && (
              <KpiBig
                bottomContent={
                  (dataKpisCountries.fraud_rate
                    ? formatNumberFull(dataKpisCountries.fraud_rate)
                    : 0) + " bps"
                }
                topContent={"Fraud rate"}
                styleClass="mb-3"
              />
            )}
            {fraudTrxViz && (
              <Kpi
                leftContent={
                  (dataKpisCountries.fraud_trx
                    ? dataKpisCountries.fraud_trx >= 1000
                      ? formatNumberFull(dataKpisCountries.fraud_trx / 1000)
                      : dataKpisCountries.fraud_trx / 1000
                    : 0) + " K"
                }
                rightContent={"Fraudulent transactions"}
                styleClass="mb-3"
              />
            )}
            {fraudAmountViz && (
              <Kpi
                leftContent={
                  (dataKpisCountries.fraud_amount
                    ? formatNumberFull(dataKpisCountries.fraud_amount)
                    : 0) +
                  " " +
                  currencySymbol
                }
                rightContent={"Fraudulent amount"}
                styleClass="mb-3"
              />
            )}
            {avgGenuineTicketViz && (
              <Kpi
                leftContent={
                  (dataKpisCountries.avg_genuine_ticket
                    ? formatNumberFull(dataKpisCountries.avg_genuine_ticket)
                    : 0) +
                  " " +
                  currencySymbol
                }
                rightContent={"Average genuine ticket"}
                color={_variablesTwoOranges.primaryOrangeLight}
                styleClass="mb-3"
              />
            )}
            {avgFraudTicketViz && (
              <Kpi
                leftContent={
                  (dataKpisCountries.avg_fraud_ticket
                    ? formatNumberFull(dataKpisCountries.avg_fraud_ticket)
                    : 0) +
                  " " +
                  currencySymbol
                }
                rightContent={"Average fraudulent ticket"}
                styleClass="mb-3"
              />
            )}
            <hr style={{ marginBottom: "2rem" }} />
            {ticketRangeVix && (
              <div className="d-flex justify-content-end">
                <div style={{ marginBottom: "1rem" }}>
                  <Download
                    disabled={selectedCountries.length === 0}
                    section="FraudGeography"
                    chartName="Fraud rate by ticket range"
                    data={mergedDataByTicketRange}
                    graphType="pie"
                    label={"Ticket Range"}
                    productType={productType}
                    country={selectedCountries}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    measure={
                      transactionType === "count"
                        ? "Transaction Count"
                        : "Volumes"
                    }
                    fields={[
                      { field: "Fraud Rate Overall", value: "value_all" },
                      { field: "Fraud Rate Country", value: "value_country" },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <Switcher
                isShared={true}
                sharedState={transactionType}
                pages={[
                  {
                    title: "Transactions",
                    onClick: () => {
                      setTransactionType("count");
                    },
                    value: "count",
                  },
                  {
                    title: "Volumes",
                    onClick: () => {
                      setTransactionType("volume");
                    },
                    value: "volume",
                  },
                ]}
              />
            </div>
            {ticketRangeVix && (
              <>
                <SmallTitleFlex
                  title={"Fraud rate by ticket range"}
                  tooltip={
                    "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each ticket range.<br /><br />" +
                    "100 bps = 1%"
                  }
                />
                <McBarChartTrafficLight
                  height={
                    60 +
                    (dataByTicketCountries.length > 0
                      ? dataByTicketCountries.length * 50
                      : 300)
                  }
                  data={dataByTicketCountries}
                  dataGuide={{
                    y: "label",
                    barsData: [
                      { dataName: "value", friendlyName: "Fraud rate" },
                    ],
                  }}
                  tooltipMeasureUnit=" bps"
                  marginLeft
                />
              </>
            )}
            <hr />
            {dashboardVersion2 && subChannelProgramVix ? (
              <>
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"Fraud rate by subchannel & program"}
                    tooltip={
                      "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program and subchannel.<br /><br />" +
                      "100 bps = 1%"
                    }
                  />
                  <Download
                    disabled={selectedCountries.length === 0}
                    section="FraudGeography"
                    chartName="Fraud rate by subchannel & program"
                    data={mergedDataBySubchannelAndProgram}
                    graphType="pie"
                    label={"Subchannel "}
                    productType={productType}
                    country={selectedCountries}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      { field: "3DS Overall", value: "rate_3ds_all" },
                      { field: "3DS Country", value: "rate_3ds_country" },
                      { field: "Non 3DS Overall", value: "rate_non_3ds_all" },
                      {
                        field: "Non 3DS Country",
                        value: "rate_non_3ds_country",
                      },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <McBarChartStacked
                  height={
                    120 +
                    (dataBySubchannelAndProgram.length > 0
                      ? dataBySubchannelAndProgram.length * 60
                      : 120)
                  }
                  data={padArrayProgramAndSubchannel(
                    dataBySubchannelAndProgramCountries,
                    dataBySubchannelAndProgram
                  )}
                  dataGuide={{
                    y: "subchannel",
                    barsData: [
                      {
                        firstBar: "rate_3ds",
                        secondBar: "rate_non_3ds",
                        friendlyName: "Fraud rate by subchannel and program",
                      },
                    ],
                  }}
                  tooltipMeasureUnit="bps"
                  marginLeft
                  background={false}
                />
              </>
            ) : programVix ? (
              <>
                <SmallTitleFlex
                  title={"Fraud rate by program"}
                  tooltip={
                    "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program.<br /><br />" +
                    "100 bps = 1%"
                  }
                />
                <McBarChartTrafficLight
                  height={
                    60 +
                    (dataByProgram.length > 0 ? dataByProgram.length * 60 : 120)
                  }
                  data={padArrayProgram(dataByProgramCountries, dataByProgram)}
                  dataGuide={{
                    y: "label",
                    barsData: [
                      { dataName: "value", friendlyName: "Fraud rate" },
                    ],
                  }}
                  tooltipMeasureUnit="bps"
                  marginLeft
                />
              </>
            ) : null}
            {dashboardVersion2 && trxShareReasonCodeVix && (
              <>
                <hr />
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"Fraud transaction share by reason code"}
                    tooltip={
                      "Fraud transactions share split over the different fraud reason codes"
                    }
                  />
                  <Download
                    disabled={selectedCountries.length === 0}
                    section="FraudGeography"
                    chartName="Transaction share by reason code"
                    data={mergedDataByReasonCode}
                    graphType="pie"
                    label={"Reason Code"}
                    productType={productType}
                    country={selectedCountries}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      { field: "Percent Overall (%)", value: "percent_all" },
                      {
                        field: "Percent Country (%)",
                        value: "percent_country",
                      },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <McBarChartVertical
                  data={padArray(
                    dataTopFiveReasonCodeSplitCountries,
                    dataTopFiveReasonCodeSplit
                  )}
                  dataGuide={{
                    y: "label",
                    barsData: [
                      { dataName: "percent", friendlyName: "Percentage" },
                    ],
                  }}
                  height={400}
                  labels={["Fraud Categories"]}
                  twoYs={false}
                  maxData={maxReasonCodeSplitCountries}
                  isPercentage={true}
                />
              </>
            )}
          </div>
        }
      />
    </LayoutPageContainer>
  );
};

export default FraudGeography;
