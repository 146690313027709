import { useState, useContext } from "react";
import { Tooltip } from "react-tooltip";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  formatNumberFull,
  formatErrorForAlert,
  formatNumberLocale,
} from "../../services/UtilsService";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import Loadable from "../Loader/Loadable";
import Alert from "../Alert/Alert";
import Switcher from "../Switcher/Switcher";
import SmallTitleFlex from "../Title/SmallTitleFlex";
import McBarChart from "../McCharts/McBarChart";
import TableYoY from "../TableYoY/TableYoY";
import KpiRow from "../Kpi/KpiRow";
import KpiProgressChart from "../Kpi/KpiProgressChart";
import TooltipInfo from "../TooltipInfo/TooltipInfo";
import { _variablesTwoOranges } from "../../styles/_variables";
import { findMax } from "../../services/UtilsService";
import "./TableDeepDive.css";
import Download from "../Button/Download";

const TableDeepDive = ({
  data = [],
  startDate,
  endDate,
  dateLabels,
  timePeriod,
  productType,
  currencySymbol,
}) => {
  // global
  const { merchantName, sidebarExpanded } = useContext(AccountContext);
  const [alert, setAlert] = useState({});

  // charts
  const [isLoadingMonthlyPerformance, setIsLoadingMonthlyPerformance] =
    useState(false);
  const [dataMonthlyPerformance, setDataMonthlyPerformance] = useState([]);
  const [dataYoYVariation, setDataYoYVariation] = useState([]);
  const [isLoadingAverageTicket, setIsLoadingAverageTicket] = useState(false);
  const [dataAverageTicket, setDataAverageTicket] = useState([]);

  // others
  const [selectedCountry, setSelectedCountry] = useState("");
  const [dataGuide, setDataGuide] = useState({
    x: "month",
    barsData: [
      { dataName: "trxCard", friendlyName: "Card present" },
      { dataName: "trxNoCard", friendlyName: "Card not present" },
    ],
  });
  const [dataGuideYoY, setDataGuideYoY] = useState("transactions");
  const [maxData, setMaxData] = useState(0);

  const openPopup = (country) => {
    setSelectedCountry(country.countryFullName);
    getMonthlyPerformance(country.countryFullName.toUpperCase());
    getAverageTicket(country.countryFullName.toUpperCase());
  };

  const changeDataGuide = (switchBtn) => {
    if (switchBtn === "transactions") {
      setDataGuide({
        x: "month",
        barsData: [
          { dataName: "trxCard", friendlyName: "Card present" },
          { dataName: "trxNoCard", friendlyName: "Card not present" },
        ],
      });
    } else if (switchBtn === "volumes") {
      setDataGuide({
        x: "month",
        barsData: [
          { dataName: "volCard", friendlyName: "Card present" },
          { dataName: "volNoCard", friendlyName: "Card not present" },
        ],
      });
    } else {
      setDataGuide({});
    }

    setDataGuideYoY(switchBtn);
  };

  const getMonthlyPerformance = (country) => {
    let body = {
      merchant_name: merchantName,
      start_date: startDate,
      end_date: endDate,
      country: country,
      product_type: productType,
    };

    setIsLoadingMonthlyPerformance(true);

    FetchService.post(
      "/api/dashboard/performance/geography/country-monthly-performance",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        let tempMonthly = res.data.map((el) => {
          return {
            month: el.yearmonth ? el.yearmonth : "N/A",
            trxCard: el.current_freq_count_trx_card_present
              ? el.current_freq_count_trx_card_present / 1000
              : 0,
            trxNoCard: el.current_freq_count_trx_card_not_present
              ? el.current_freq_count_trx_card_not_present / 1000
              : 0,
            volCard: el.current_freq_count_amount_card_present
              ? el.current_freq_count_amount_card_present / 1000
              : 0,
            volNoCard: el.current_freq_count_amount_card_not_present
              ? el.current_freq_count_amount_card_not_present / 1000
              : 0,
          };
        });
        setDataMonthlyPerformance(tempMonthly);

        // find max for better chart visualization
        setMaxData(findMax(tempMonthly));

        let tempYoY = res.data.map((el) => {
          return {
            month: el.yearmonth ? el.yearmonth : "N/A",
            trxCard: el.year_on_year_trx_card_present
              ? el.year_on_year_trx_card_present
              : 0,
            trxNoCard: el.year_on_year_trx_card_not_present
              ? el.year_on_year_trx_card_not_present
              : 0,
            volCard: el.year_on_year_amount_card_present
              ? el.year_on_year_amount_card_present
              : 0,
            volNoCard: el.year_on_year_amount_card_not_present
              ? el.year_on_year_amount_card_not_present
              : 0,
          };
        });
        setDataYoYVariation(tempYoY);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMonthlyPerformance(false));
  };

  const getAverageTicket = (country) => {
    let body = {
      merchant_name: merchantName,
      start_date: startDate,
      end_date: endDate,
      country: country,
      product_type: productType,
    };

    setIsLoadingAverageTicket(true);

    FetchService.post(
      "/api/dashboard/performance/geography/country-average-ticket",
      body
    )
      .then((res) => {
        let temp = [];
        temp.push(res.data.share_percent);
        temp.push(formatNumberFull(res.data.avg_ticket_card_present));
        temp.push(formatNumberFull(res.data.avg_ticket_card_not_present));
        setDataAverageTicket(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAverageTicket(false));
  };

  // 12 months empty array
  const dataFullMP = dateLabels.map((month) => ({
    month,
    trxCard: null,
    trxNoCard: null,
    volCard: null,
    volNoCard: null,
  }));

  const dataFullYoY = dateLabels.map((month) => ({
    month,
    trxCard: null,
    trxNoCard: null,
    volCard: null,
    volNoCard: null,
  }));

  // check if there is data for each month and insert
  if (dataMonthlyPerformance) {
    for (let i = 0; i < dataFullMP.length; i++) {
      const monthlyData = dataMonthlyPerformance.find(
        (data) => data.month === dataFullMP[i].month
      );

      if (monthlyData && monthlyData.trxCard !== null) {
        dataFullMP[i] = {
          month: dataFullMP[i].month,
          trxCard: monthlyData.trxCard,
          trxNoCard: monthlyData.trxNoCard,
          volCard: monthlyData.volCard,
          volNoCard: monthlyData.volNoCard,
        };
      }
    }
  }

  if (dataYoYVariation) {
    for (let i = 0; i < dataFullYoY.length; i++) {
      const monthlyData = dataYoYVariation.find(
        (data) => data.month === dataFullYoY[i].month
      );

      if (monthlyData && monthlyData.trxCard !== null) {
        dataFullYoY[i] = {
          month: dataFullYoY[i].month,
          trxCard: monthlyData.trxCard,
          trxNoCard: monthlyData.trxNoCard,
          volCard: monthlyData.volCard,
          volNoCard: monthlyData.volNoCard,
        };
      }
    }
  }

  // filter by time period
  const filteredDataMP = dataFullMP.slice(timePeriod[0], timePeriod[1] + 1);
  const filteredDataYoY = dataFullYoY.slice(timePeriod[0], timePeriod[1] + 1);

  return (
    <>
      <div className="table-responsive">
        <Tooltip id={"info"} noArrow className="tooltip-container" />
        {data.map((country, index) => (
          <Tooltip
            id={country.country}
            noArrow
            className="tooltip-container"
            key={index + "tooltipcountry"}
          />
        ))}

        {data.length > 0 ? (
          <table className="table">
            <tbody>
              <tr className="noborder-tr">
                <td className="legend-td border-td">
                  <div
                    className="d-flex justify-content-start align-items-center"
                    data-tooltip-id={"info"}
                    data-tooltip-html={
                      "For more information please click on country abbreviation"
                    }
                    data-tooltip-place={"top"}
                    data-tooltip-variant="light"
                  >
                    <div>
                      Country
                      <br />
                      deep dive
                    </div>
                    <div className="ms-1">
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                </td>
                {data.map((country, index) => (
                  <td
                    key={index + "ddfirst"}
                    className="border-td text-center"
                    data-tooltip-id={country.country}
                    data-tooltip-html={country.countryFullName}
                    data-tooltip-place={"top"}
                    data-tooltip-variant="light"
                  >
                    <Button
                      text={country.country}
                      style={{ minWidth: "60px" }}
                      dataBsToggle="modal"
                      dataBsTarget={"geoModal"}
                      onClick={() => openPopup(country)}
                    />
                  </td>
                ))}
              </tr>
              <tr className="noborder-tr">
                <td className="legend-td border-td">
                  <div>Average ticket</div>
                </td>
                {data.map((country, index) => (
                  <td
                    key={index + "ddsecond"}
                    className="border-td text-center"
                  >
                    {formatNumberFull(country.avg)} {currencySymbol}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>

      <Modal
        dataBsTarget={"geoModal"}
        title={<b>{selectedCountry}</b>}
        modalBody={
          <>
            <div className="d-flex justify-content-between">
              <SmallTitleFlex
                title={"Monthly performance"}
                tooltip={
                  "Number of transactions and volumes (spend) by channel: card present (physical in-store) and card not present (online)."
                }
              />

              <Download
                section="PerformanceGeography"
                chartName={`${selectedCountry}_MonthlyPerformance`}
                data={dataMonthlyPerformance}
                graphType="barChart"
                productType={productType}
                label={"Time Period"}
                field1={"Transactions Card Present"}
                value1={"trxCard"}
                field2={"Transactions Card Not Present"}
                value2={"trxNoCard"}
                field3={"Volume Card Present"}
                value3={"volCard"}
                field4={"Volume Card Not Present"}
                value4={"volNoCard"}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>

            <Switcher
              pages={[
                {
                  title: "Transactions",
                  onClick: () => {
                    changeDataGuide("transactions");
                  },
                },
                {
                  title: "Volumes",
                  onClick: () => {
                    changeDataGuide("volumes");
                  },
                },
              ]}
            />

            <Loadable
              isLoading={isLoadingMonthlyPerformance}
              styleClass="text-center my-5"
            >
              <div className="mb-5">
                <McBarChart
                  data={filteredDataMP}
                  dataGuide={dataGuide}
                  labels={
                    dataGuide.barsData[0].dataName === "trxCard"
                      ? ["Transactions (K)"]
                      : [`Volumes (K${currencySymbol})`]
                  }
                  twoYs={false}
                  tooltipMeasureUnit={
                    dataGuide.barsData[0].dataName === "trxCard"
                      ? " K"
                      : ` K${currencySymbol}`
                  }
                  maxData={maxData}
                />
              </div>
            </Loadable>

            <SmallTitleFlex
              title={"YoY Change"}
              tooltip={
                "YoY Change represents the monthly performance of a given month vs the same month of the previous year."
              }
            />
            <TableYoY data={filteredDataYoY} dataGuide={dataGuideYoY} />

            <hr />

            <SmallTitleFlex
              title={"Share and Average ticket"}
              tooltip={
                "Share of Country transactions (both card present and card not present) on total merchant transactions.<br /><br />" +
                "Average ticket is calculated as: volumes (spend) divided by transactions."
              }
            />

            <Loadable
              isLoading={isLoadingAverageTicket}
              styleClass="text-center my-5"
            >
              <KpiRow
                kpis={[
                  {
                    leftContent: (
                      <KpiProgressChart
                        bar={{
                          value: dataAverageTicket[0],
                          color: _variablesTwoOranges.primaryOrange,
                        }}
                      />
                    ),
                    righContent: "Share of country on overall merchant",
                    autofit: false,
                    styleClass:
                      "deep-dive-kpi d-flex justify-content-center align-items-center",
                  },
                  {
                    leftContent:
                      (dataAverageTicket[1] ? dataAverageTicket[1] : "0") +
                      " " +
                      currencySymbol,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Card present
                      </div>
                    ),
                    styleClass:
                      "deep-dive-kpi d-flex justify-content-center align-items-center",
                  },
                  {
                    leftContent:
                      (dataAverageTicket[2] ? dataAverageTicket[2] : "0") +
                      " " +
                      currencySymbol,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Card not present
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrangeLight,
                    styleClass:
                      "deep-dive-kpi d-flex justify-content-center align-items-center",
                  },
                ]}
              />
            </Loadable>

            <Alert
              styleClass={""}
              alertText={alert.message}
              styleColor={alert.messageType}
              onClick={setAlert}
              paddingLeft={false}
            />
          </>
        }
      />
    </>
  );
};

export default TableDeepDive;
